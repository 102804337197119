import { TurnIntoable } from '../../../design-system/molecules/TurnIntoMenu.tsx';
import BlockFormattingMenu from '../../../design-system/organisms/BlockFormattingMenu.tsx';
import { getRectFromEl } from '../../../domHelpers/Rect.ts';
import { subtractVector } from '../../../domHelpers/Point.ts';
import HoverNextToPoint from '../../../domHelpers/hoverNextTo/HoverNextToPoint.tsx';
import { EditorStateGeneric } from '../../EditorStateGeneric.ts';
import { Block, isTextBlock } from 'editor-content/Block.js';
import splitContentByBlockSelection from '../../selection/splitContentByBlockSelection.ts';
import { isBlockSelection } from '../../EditorSelection.ts';

type BlockFormattingMenuProps<AvailableBlock extends Block> = {
  turnIntoables: TurnIntoable[];
  editorState: EditorStateGeneric<AvailableBlock>;
  getFocusedEl: () => HTMLElement | undefined;
};

const BlockSelectionFormattingMenu = <AvailableBlock extends Block>({
  turnIntoables,
  editorState,
  getFocusedEl,
}: BlockFormattingMenuProps<AvailableBlock>) => {
  if (!isBlockSelection(editorState.selection)) return null;
  const [, selectedBlocks] = splitContentByBlockSelection(
    editorState.content,
    editorState.selection,
  );

  if (!selectedBlocks.every(isTextBlock)) return null;

  return (
    <HoverNextToPoint
      usePortal
      viewportPolicy="none"
      getPoint={(childElement) => {
        const el = getFocusedEl();

        if (!el) {
          return [NaN, NaN];
        }

        const rect = getRectFromEl(el);
        const { height, width } = childElement.getBoundingClientRect();

        return subtractVector(
          [(rect[0][0] + rect[1][0]) / 2, rect[0][1]],
          [width / 2, height + 8],
        );
      }}
    >
      <BlockFormattingMenu turnIntoables={turnIntoables} />
    </HoverNextToPoint>
  );
};

export default BlockSelectionFormattingMenu;
