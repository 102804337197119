import ContentSelection from '../selection/contentSelection/ContentSelection.js';
import { EditorStateGeneric } from '../EditorStateGeneric.js';
import { blockSelection, BlockSelection } from '../selection/BlockSelection.js';
import { isTextSelection } from '../EditorSelection.js';
import getSelectedBlock from '../../pages/zeck/editor/BodyEditor/getSelectedBlock.js';

export default function pressShiftArrowDown<BlockType>(
  generateBlockEditor: (b: BlockType) => {
    pressShiftArrowDown: (selection: ContentSelection) => boolean;
  },
  initialState: EditorStateGeneric<BlockType>,
): { content: BlockType[]; selection: BlockSelection } | void {
  const { content, selection } = initialState;

  if (!selection) return;

  if (isTextSelection(selection)) {
    const selectedBlock = getSelectedBlock(content, selection);

    if (!selectedBlock) return;

    const targetBlockEditor = generateBlockEditor(selectedBlock);

    if (!targetBlockEditor.pressShiftArrowDown(selection.offset)) return;

    return {
      content,
      selection: blockSelection(selection.index, selection.index),
    };
  }

  if (selection.focusIndex >= content.length - 1) {
    return;
  }

  return {
    content,
    selection: blockSelection(selection.anchorIndex, selection.focusIndex + 1),
  };
}
