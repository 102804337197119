import React from 'react';
import { ElementAndData } from '../../junkDrawer/useElementAndDataArray.js';
import useHoveredBlockIndex from './useHoveredBlockIndex.js';
import FloatingAddBlockButton from './FloatingAddBlockButton.js';
import { AnimatePresence, motion } from 'framer-motion';

const AddBlockButtonWithHover: React.FC<{
  blocksWithEl: ElementAndData<unknown>[];
  leftGutterRef: React.RefObject<HTMLElement>;
  onAddNewBlock: (index: number) => void;
}> = ({ blocksWithEl, leftGutterRef, onAddNewBlock }) => {
  const hoveredIndex = useHoveredBlockIndex(blocksWithEl, leftGutterRef);

  return (
    <AnimatePresence>
      {hoveredIndex >= 0 && (
        <motion.div
          key={hoveredIndex}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.2 } }}
          // exit={{ opacity: 0, transition: { duration: 0.1 } }}
          // 24-07-24: disabled because of a bug in framer motion
          // will be fixed when this is merged https://github.com/framer/motion/pull/2741
        >
          <FloatingAddBlockButton
            getBlockEl={() => blocksWithEl[hoveredIndex]?.getEl()}
            onClick={() => onAddNewBlock(hoveredIndex)}
            leftGutterRef={leftGutterRef}
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default AddBlockButtonWithHover;
