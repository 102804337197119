import isTouchDeviceFlow from '../../../../services/isTouchDeviceFlow.ts';
import { Section, Zeck } from '../../../../types.ts';
import { useNavigate } from 'react-router-dom';
import React, { useRef } from 'react';
import PreviewPage from './PreviewPage.tsx';
import LinkDrawer from '../../links/LinkDrawer/LinkDrawer.tsx';
import { sectionEditPath, zeckEditPath } from '../../../../services/Paths.ts';
import useScrollToSection from '../useScrollToSection.ts';
import ZeckView from '../ZeckView.tsx';
import { BrandKitResource } from '../../../../design-system/zeck/WithBrandKit.tsx';
import { UserAndCompany } from '../../../../userAndCompany/FetchUserAndCompany.tsx';
import { MeetingMinutes } from '../../../../api/endpoints/createMeetingMinutesApi.ts';
import MeetingMinutesView from '../../../../meetingMinutes/MeetingMinutesView.js';
import { isSignedMinutes } from '../../../meetingMinutes/MeetingMinutes.js';
import MeetingMinutesSignature from '../../../../meetingMinutes/MeetingMinutesSignature.js';
import SectionView from '../SectionView.js';
import {
  BlockPrevoteResult,
  BlockPrevoteTally,
  generateZeckVoteCapabilities,
} from '../../voting/VoteCapability.js';
import { Prevote } from '../../../../types/Prevote.ts';
import { zeckResourceToLinkable } from '../../../../types/ZeckResource.ts';
import useLinkDrawer from '../../links/LinkDrawer/useLinkDrawer.js';
import useLinkTooltips from '../../links/LinkTooltips/useLinkTooltips.js';
import LinkTooltips from '../../links/LinkTooltips/LinkTooltips.js';

type SectionPreviewPageProps = {
  brandKitResource: BrandKitResource;
  zeck: Zeck & {
    actions: { publish: () => Promise<void> };
  };
  section?: Section;
  userAndCompany: UserAndCompany;
  meetingMinutes: MeetingMinutes[];
  prevoteData: {
    results: BlockPrevoteResult[];
    tallies: BlockPrevoteTally[];
    boardDirectorCount: number;
    currentUserPrevotes: Prevote[];
  };
};

const PreviewSectionPageView: React.FunctionComponent<
  SectionPreviewPageProps
> = ({
  brandKitResource,
  zeck,
  section,
  userAndCompany,
  meetingMinutes,
  prevoteData,
}) => {
  const navigate = useNavigate();
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const sectionsWithRef = useScrollToSection(zeck, section);

  function scrollToSectionOrZeckCover(sectionId?: string) {
    if (!sectionId) {
      scrollContainerRef.current?.scrollTo({ top: 0 });
      return;
    }

    const maybeSectionWithRef = sectionsWithRef.find(
      ({ data: section }) => section.id === sectionId,
    );
    if (maybeSectionWithRef) {
      const { getEl } = maybeSectionWithRef;
      getEl()?.scrollIntoView();
    }
  }

  const sectionForLinks = section || zeck.sections[0];

  const { zeckFinalizeVoteCapability, zeckPrevoteCapability } =
    generateZeckVoteCapabilities({
      userIsAdmin: userAndCompany.activeCompany.role === 'admin',
      userCanManageMinutes:
        userAndCompany.activeCompany.permissions.canManageMinutes,
      userIsBoardDirector: userAndCompany.activeCompany.boardDirector,
      takePrevote: null,
      removePrevote: null,
      takeVote: null,
      prevoteData,
    });

  const linkables = zeck.resources.map((r) =>
    zeckResourceToLinkable(r, zeck.companyId, 'preview'),
  );

  const linkDrawerState = useLinkDrawer();
  const linkTooltipBehavior = useLinkTooltips({
    linkables: linkables,
    onClickLink: linkDrawerState.openDrawer,
  });

  return (
    <div {...linkTooltipBehavior.linkListeners}>
      <PreviewPage
        brandKitResource={brandKitResource}
        scrollContainerRef={scrollContainerRef}
        userAndCompany={userAndCompany}
        zeck={zeck}
        section={sectionForLinks}
        onClickExitPreview={() => {
          if (isTouchDeviceFlow()) {
            navigate(`/company/${userAndCompany.activeCompany.id}`);
          } else if (section && sectionForLinks) {
            navigate(sectionEditPath(sectionForLinks, zeck.companyId));
          } else {
            navigate(zeckEditPath(zeck));
          }
        }}
        scrollToSectionOrZeckCover={scrollToSectionOrZeckCover}
      >
        <ZeckView
          scrollContainerRef={scrollContainerRef}
          zeck={zeck}
          companyName={userAndCompany.activeCompany.name}
          linkables={linkables}
          sectionsWithRef={sectionsWithRef}
          zeckPrevoteCapability={zeckPrevoteCapability}
          zeckFinalizeVoteCapability={zeckFinalizeVoteCapability}
        />
      </PreviewPage>
      <LinkTooltips
        linkTooltipState={linkTooltipBehavior.linkTooltipState}
        onClickLink={linkDrawerState.openDrawer}
        hideNotFoundTooltip={true}
      />
      <LinkDrawer
        {...{
          linkDrawerState,
          onScrollInDrawer: () => {
            linkTooltipBehavior.onScroll();
          },
          renderContent: (linkable, scrollContainerRef) => {
            switch (linkable.type) {
              case 'section': {
                const section = zeck.sections.find(
                  (section) => section.id === linkable.itemId,
                );
                if (!section) return null;

                return (
                  <SectionView
                    scrollContainerRef={scrollContainerRef}
                    section={section}
                    linkables={linkables}
                    zeckPrevoteCapability={zeckPrevoteCapability}
                    zeckFinalizeVoteCapability={zeckFinalizeVoteCapability}
                  />
                );
              }
              case 'meeting-minutes': {
                const meetingMinutesInstance = meetingMinutes.find(
                  (meetingMinutes) => meetingMinutes.id === linkable.itemId,
                );

                if (!meetingMinutesInstance) return null;

                return (
                  <MeetingMinutesView
                    meetingMinutes={meetingMinutesInstance}
                    additionalContent={
                      isSignedMinutes(meetingMinutesInstance) ? (
                        <MeetingMinutesSignature
                          signature={meetingMinutesInstance.signatures[0]}
                        />
                      ) : null
                    }
                  />
                );
              }
            }
          },
        }}
      />
    </div>
  );
};

export default PreviewSectionPageView;
