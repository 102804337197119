import React, { useRef } from 'react';
import AgendaEditable from './AgendaEditable.tsx';
import mergeRefs from '../../../../junkDrawer/mergeRefs.ts';
import SelectableDiv from './SelectableDiv.tsx';
import BlockActions from './BlockActions.ts';
import { callHandlers } from '../../../../editor/domFacing/events/isKeyMatch.ts';
import handleNonTextBlockKeyEvents from './handleNonTextBlockKeyEvents.ts';
import { AgendaBlock } from 'editor-content/AgendaBlock.js';
import { contentSelection } from '../../../../editor/selection/contentSelection/ContentSelection.ts';
import Linkable from 'editor-content/html/Linkable.ts';

type AgendaEditableWithKeyboardProps = {
  className?: string;
  isInBlockSelection: boolean;
  linkables: Linkable[];
} & BlockActions<AgendaBlock>;

const AgendaEditableWithKeyboard = React.forwardRef<
  HTMLElement,
  AgendaEditableWithKeyboardProps
>(function AgendaEditableWithKeyboard(
  {
    className,
    block,
    selection,
    onSelect,
    onChange,
    onNavLeft,
    onNavRight,
    onEnter,
    isInBlockSelection,
    linkables,
  },
  forwardedRef,
) {
  const ref = useRef<HTMLDivElement>(null);

  const eventTargetIsSelectableDiv = (e: React.KeyboardEvent<HTMLDivElement>) =>
    e.target !== ref.current;

  return (
    <SelectableDiv
      ref={mergeRefs([forwardedRef, ref])}
      expand
      className={className}
      onSelect={onSelect}
      internalSelection={selection}
      isInBlockSelection={isInBlockSelection}
      onKeyDown={callHandlers([
        eventTargetIsSelectableDiv,
        callHandlers([
          handleNonTextBlockKeyEvents({
            onEnter,
            onNavLeft,
            onNavRight,
          }),
        ]),
      ])}
    >
      <AgendaEditable
        // ref={mergeRefs([forwardedRef])}
        value={block}
        onChange={(v) => onChange(v, contentSelection(0))}
        linkables={linkables}
      />
    </SelectableDiv>
  );
});

export default AgendaEditableWithKeyboard;
