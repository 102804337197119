import './design-system/variables.css';
import './design-system/colors.css';
import './global.css';

import useViewportHeight from './services/useViewportHeight.ts';
import ErrorToaster from './errorToaster/ErrorToaster.tsx';
import HoverLayer from './domHelpers/hoverNextTo/HoverLayer.tsx';
import ApiSession from './api/ApiSession.tsx';
import FeatureFlags from './feature-flags/FeatureFlags.tsx';
import { RootEventListener } from './junkDrawer/useRootEventListener.js';
import makeUnauthenticatedRoutes from './routes/unauthenticatedRoutes.tsx';
import { useEffect } from 'react';
import { characterOffsetToBaseAndExtent } from 'selection-character-offset';
import { Userpilot } from 'userpilot';
import { CompanyDependentRoutes } from './CompanyDependentRoutes.js';
import { Routes } from './Routes.js';

Object.assign(window, { _testhelpers: { characterOffsetToBaseAndExtent } });

export default function App() {
  useViewportHeight();

  useEffect(() => {
    // do not want on localhost, test, or dev to keep user count in userpilot low since we pay per user
    if (
      document.location.host === 'z.zeck.app' ||
      document.location.host === 'uat.zeck.app'
    ) {
      Userpilot.initialize('NX-01c55bf8');
    }
  }, []);

  return (
    <ErrorToaster>
      <FeatureFlags>
        <RootEventListener>
          <ApiSession>
            {(apiSession) => {
              if (apiSession.authenticated) {
                return <CompanyDependentRoutes apiSession={apiSession} />;
              } else {
                return (
                  <Routes
                    routes={makeUnauthenticatedRoutes(apiSession)}
                    user={null}
                  />
                );
              }
            }}
          </ApiSession>
        </RootEventListener>
      </FeatureFlags>
      <HoverLayer />
    </ErrorToaster>
  );
}
