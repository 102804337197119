import React, { RefObject, useEffect, useRef, useState } from 'react';
import styles from './Image.module.scss';
import ImageModal from '../organisms/ImageModal.tsx';
import { Icon } from 'icons';
import Image from '../atoms/Image.tsx';
import ImageCaption from '../atoms/ImageCaption.tsx';
import ImageBlockLayout from '../atoms/ImageLayout.tsx';
import { ImageBlock } from 'editor-content/Block.js';
import useApi from '../../api/useApi.ts';
import mergeRefs from '../../junkDrawer/mergeRefs.ts';

type ImageProps = {
  block: ImageBlock;
  className?: string;
  isPresenting?: boolean;
  scrollContainerRef?: RefObject<HTMLElement>;
};

const ImageView = React.forwardRef<HTMLDivElement, ImageProps>(
  function ImageView(
    {
      block,
      scrollContainerRef,
      className,
      isPresenting = false,
      ...otherProps
    },
    forwardedRef,
  ) {
    const { guid, caption, dimensions, width, align } = block;
    const [fullScreen, setFullScreen] = useState(false);

    const imageRef = useRef<HTMLElement>(null);
    const [visible, setVisible] = useState(false);
    useEffect(() => {
      if (!scrollContainerRef?.current || !imageRef.current) {
        return;
      }

      const options = {
        root: scrollContainerRef.current,
        rootMargin: '1000px 0px 1000px 0px',
        threshold: 0,
      };

      const observer = new IntersectionObserver((entries) => {
        if (entries[0]?.isIntersecting) {
          setVisible(true);
        }
      }, options);
      observer.observe(imageRef.current);

      return () => {
        observer.disconnect();
      };
    }, [scrollContainerRef]);

    const { getImage } = useApi();
    const [src, setSrc] = useState<string>('');
    useEffect(() => {
      if (visible || !scrollContainerRef) {
        getImage(guid).then(setSrc);
      }
    }, [guid, setSrc, getImage, visible, scrollContainerRef]);

    return (
      <>
        <ImageBlockLayout
          id={block.id}
          {...otherProps}
          {...{
            width,
            className,
            align,
            image: (
              <div
                onClick={() => setFullScreen(true)}
                className={styles.image_fullScreenEnabled}
              >
                <Image
                  ref={mergeRefs([forwardedRef, imageRef])}
                  src={src}
                  dimensions={dimensions}
                />
                <div className={styles.image_icon}>
                  <Icon name="fullscreen" />
                </div>
              </div>
            ),
            caption: <ImageCaption>{caption}</ImageCaption>,
          }}
        />
        <ImageModal
          src={src}
          imageBlock={block}
          isOpen={fullScreen}
          isPresenting={isPresenting}
          onRequestClose={() => {
            setFullScreen(false);
          }}
        />
      </>
    );
  },
);

export default ImageView;
