import ContentSelection, {
  contentSelection,
} from '../../../../editor/selection/contentSelection/ContentSelection.js';
import {
  BulletedListItemBlock,
  CartaCapTableBlock,
  createNumberedListItemBlock,
  createParagraphBlock,
  FileBlock,
  getTextFromBlock,
  HeadingBlock,
  ImageBlock,
  LabelBlock,
  NumberedListItemBlock,
  numberedListItemBlock,
  ParagraphBlock,
  VideoBlock,
} from 'editor-content/Block.js';
import splitTextNodesFromContentSelection from '../../../../editor/blocks/textNode/splitTextNodesFromContentSelection.js';
import { areTextNodesEmpty } from 'editor-content/TextNode.js';
import { AgendaBlock } from 'editor-content/AgendaBlock.js';
import paragraphPressEnterStrategy from '../../../../editor/blocks/paragraph/paragraphPressEnterStrategy.js';
import bulletedListItemPressEnterStrategy from '../../../../editor/blocks/bulletedListItem/bulletedListItemPressEnterStrategy.js';
import { ContentPatch } from '../../../../editor/ContentPatch.js';
import { DividerBlock } from 'editor-content/DividerBlock.js';
import pressEnter from '../../../../editor/actions/pressEnter.js';
import { HydratedBlock } from '../../../../types/HydratedBlock.js';
import { VoteBlockHydrated } from '../../../../VoteBlockHydrated.js';
import { TableBlock } from 'editor-content/TableBlock.js';
import isTextBlockEmpty from '../../../../editor/blocks/isTextBlockEmpty.js';

type BlockKeyboardBehavior<B extends HydratedBlock> = {
  // type: B['type'];
  // what happens to the zeck data when you press enter in a block
  pressEnter: (
    block: B,
    selection: ContentSelection,
  ) => ContentPatch<HydratedBlock[]>;
};

const ParagraphBlockKeyboardBehavior: BlockKeyboardBehavior<ParagraphBlock> = {
  pressEnter: (block, selection) => {
    return paragraphPressEnterStrategy(block, selection);
  },
};

const HeadingBlockKeyboardBehavior: BlockKeyboardBehavior<HeadingBlock> = {
  pressEnter: (block, selection) => {
    const [beforeSelection, , afterSelection] =
      splitTextNodesFromContentSelection(block.content, selection);

    if (isTextBlockEmpty(block)) {
      return {
        contentSubset: [ParagraphBlock(block.id, [])],
        selection: {
          index: 0,
          offset: selection,
        },
      };
    } else if (!areTextNodesEmpty(beforeSelection)) {
      return {
        contentSubset: [
          HeadingBlock(block.id, beforeSelection),
          createParagraphBlock(afterSelection),
        ],
        selection: {
          index: 1,
          offset: contentSelection(0),
        },
      };
    } else if (!areTextNodesEmpty(afterSelection)) {
      return {
        contentSubset: [
          createParagraphBlock(beforeSelection),
          HeadingBlock(block.id, afterSelection),
        ],
        selection: {
          index: 1,
          offset: contentSelection(0),
        },
      };
    }

    return {
      contentSubset: [block],
      selection: {
        index: 0,
        offset: selection,
      },
    };
  },
};

const LabelBlockKeyboardBehavior: BlockKeyboardBehavior<LabelBlock> = {
  pressEnter: (block, selection) => {
    const [contentBeforeSelection, , contentAfterSelection] =
      splitTextNodesFromContentSelection(block.content, selection);

    if (isTextBlockEmpty(block)) {
      return {
        contentSubset: [ParagraphBlock(block.id, [])],
        selection: {
          index: 0,
          offset: selection,
        },
      };
    } else if (!areTextNodesEmpty(contentBeforeSelection)) {
      return {
        contentSubset: [
          LabelBlock(block.id, contentBeforeSelection),
          createParagraphBlock(contentAfterSelection),
        ],
        selection: {
          index: 1,
          offset: contentSelection(0),
        },
      };
    } else if (!areTextNodesEmpty(contentAfterSelection)) {
      return {
        contentSubset: [
          createParagraphBlock(contentBeforeSelection),
          LabelBlock(block.id, contentAfterSelection),
        ],
        selection: {
          index: 1,
          offset: contentSelection(0),
        },
      };
    }

    return {
      contentSubset: [block],
      selection: {
        index: 0,
        offset: selection,
      },
    };
  },
};

const NumberedListItemBlockKeyboardBehavior: BlockKeyboardBehavior<NumberedListItemBlock> =
  {
    pressEnter: (block, selection) => {
      if (getTextFromBlock(block).length === 0) {
        return {
          contentSubset: [ParagraphBlock(block.id, [])],
          selection: {
            index: 0,
            offset: selection,
          },
        };
      } else {
        const [beforeSelectionTextNodes, , afterSelectionTextNodes] =
          splitTextNodesFromContentSelection(block.content, selection);

        return {
          contentSubset: [
            numberedListItemBlock(
              block.id,
              beforeSelectionTextNodes,
              block.indent,
            ),
            createNumberedListItemBlock(afterSelectionTextNodes, block.indent),
          ],
          selection: {
            index: 1,
            offset: contentSelection(0),
          },
        };
      }
    },
  };

const BulletedListItemBlockKeyboardBehavior: BlockKeyboardBehavior<BulletedListItemBlock> =
  {
    pressEnter: (block, selection) => {
      return bulletedListItemPressEnterStrategy(block, selection, (blockId) => {
        if (blockId) {
          return ParagraphBlock(blockId, []);
        }

        return createParagraphBlock([]);
      });
    },
  };

const ImageBlockKeyboardBehavior: BlockKeyboardBehavior<ImageBlock> = {
  pressEnter: (block, _selection) => {
    return {
      contentSubset: [block, createParagraphBlock([])],
      selection: {
        index: 1,
        offset: contentSelection(0),
      },
    };
  },
};

const FileBlockKeyboardBehavior: BlockKeyboardBehavior<FileBlock> = {
  pressEnter: (block, _selection) => {
    return {
      contentSubset: [block, createParagraphBlock([])],
      selection: {
        index: 1,
        offset: contentSelection(0),
      },
    };
  },
};

const VoteBlockKeyboardBehavior: BlockKeyboardBehavior<VoteBlockHydrated> = {
  pressEnter: (block, _selection) => {
    return {
      contentSubset: [block, createParagraphBlock([])],
      selection: {
        index: 1,
        offset: contentSelection(0),
      },
    };
  },
};

const AgendaBlockKeyboardBehavior: BlockKeyboardBehavior<AgendaBlock> = {
  pressEnter: (block, _selection) => {
    return {
      contentSubset: [block, createParagraphBlock([])],
      selection: {
        index: 1,
        offset: contentSelection(0),
      },
    };
  },
};

const VideoBlockKeyboardBehavior: BlockKeyboardBehavior<VideoBlock> = {
  pressEnter: (block, _selection) => {
    return {
      contentSubset: [block, createParagraphBlock([])],
      selection: {
        index: 1,
        offset: contentSelection(0),
      },
    };
  },
};

const DividerBlockKeyboardBehavior: BlockKeyboardBehavior<DividerBlock> = {
  pressEnter: (block, _selection) => {
    return {
      contentSubset: [block, createParagraphBlock([])],
      selection: {
        index: 1,
        offset: contentSelection(0),
      },
    };
  },
};

const TableBlockKeyboardBehavior: BlockKeyboardBehavior<TableBlock> = {
  pressEnter: (block, _selection) => {
    return {
      contentSubset: [block, createParagraphBlock([])],
      selection: {
        index: 1,
        offset: contentSelection(0),
      },
    };
  },
};

const CartaBlockKeyboardHavior: BlockKeyboardBehavior<CartaCapTableBlock> = {
  pressEnter: (block, _selection) => {
    return {
      contentSubset: [block, createParagraphBlock([])],
      selection: {
        index: 1,
        offset: contentSelection(0),
      },
    };
  },
};

// IIRC, _this is way_
export default pressEnter<HydratedBlock>((block) => {
  switch (block.type) {
    case 'divider':
      return {
        pressEnter: (selection) =>
          DividerBlockKeyboardBehavior.pressEnter(block, selection),
      };
    case 'table':
      return {
        pressEnter: (selection) =>
          TableBlockKeyboardBehavior.pressEnter(block, selection),
      };
    case 'paragraph':
      return {
        pressEnter: (selection) =>
          ParagraphBlockKeyboardBehavior.pressEnter(block, selection),
      };
    case 'label':
      return {
        pressEnter: (selection) =>
          LabelBlockKeyboardBehavior.pressEnter(block, selection),
      };
    case 'bulleted-list-item':
      return {
        pressEnter: (selection) =>
          BulletedListItemBlockKeyboardBehavior.pressEnter(block, selection),
      };
    case 'numbered-list-item':
      return {
        pressEnter: (selection) =>
          NumberedListItemBlockKeyboardBehavior.pressEnter(block, selection),
      };
    case 'heading':
      return {
        pressEnter: (selection) =>
          HeadingBlockKeyboardBehavior.pressEnter(block, selection),
      };
    case 'image':
      return {
        pressEnter: (selection) =>
          ImageBlockKeyboardBehavior.pressEnter(block, selection),
      };
    case 'file':
      return {
        pressEnter: (selection) =>
          FileBlockKeyboardBehavior.pressEnter(block, selection),
      };
    case 'vote':
      return {
        pressEnter: (selection) =>
          VoteBlockKeyboardBehavior.pressEnter(block, selection),
      };
    case 'agenda':
      return {
        pressEnter: (selection) =>
          AgendaBlockKeyboardBehavior.pressEnter(block, selection),
      };
    case 'video':
      return {
        pressEnter: (selection) =>
          VideoBlockKeyboardBehavior.pressEnter(block, selection),
      };
    case 'carta-cap-table':
      return {
        pressEnter: (selection) =>
          CartaBlockKeyboardHavior.pressEnter(block, selection),
      };
  }
}, createParagraphBlock);
