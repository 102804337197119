import { useCallback, useEffect, useRef, useState } from 'react';
import styles from './EditPage.module.scss';
import EditorSidebar from './sidebar/EditorSidebar.tsx';
import ReactChildrenProp from '../../../types/ReactChildrenProp.ts';
import useViewportHeight from '../../../services/useViewportHeight.ts';
import EditorTopBar from './topbar/EditorTopBar.tsx';
import cx from 'classnames';
import ZeckWithActions from './zeckCover/ZeckWithActions.ts';
import SectionWithActions from './zeckCover/SectionWithActions.ts';
import mergeRefs from '../../../junkDrawer/mergeRefs.js';

export type EditPageProps = {
  zeck: ZeckWithActions;
  section?: SectionWithActions;
  onRenameActiveSection: () => void;
  isSaved: boolean;
  children: ReactChildrenProp;
  scrollContainerRef?: React.Ref<HTMLElement>;
} & React.ComponentProps<'div'>;

const EditPage = ({
  zeck,
  section: currentSection,
  onRenameActiveSection,
  isSaved,
  children,
  onScroll,
  scrollContainerRef,
  ...otherProps
}: EditPageProps) => {
  const [menuIsOpen, setMenuIsOpen] = useState(true);
  const scrollContainer = useRef<HTMLDivElement>(null);
  useViewportHeight(scrollContainer);

  useEffect(() => {
    scrollContainer.current?.scrollTo({ top: 0 });
  }, [currentSection?.id]);

  const onClose = useCallback(() => {
    setMenuIsOpen(false);
  }, [setMenuIsOpen]);

  return (
    <div className={cx(styles.zeckEditPage)} {...otherProps}>
      <EditorSidebar
        isOpen={menuIsOpen}
        onClose={onClose}
        zeck={zeck}
        currentSection={currentSection}
        onRenameActiveSection={onRenameActiveSection}
      />
      <div className={styles.zeckEditPage__contentContainer}>
        <EditorTopBar
          zeck={zeck}
          section={currentSection}
          isSaved={isSaved}
          menuIsOpen={menuIsOpen}
          setMenuIsOpen={setMenuIsOpen}
        />
        <div
          ref={mergeRefs([
            scrollContainer,
            ...(scrollContainerRef ? [scrollContainerRef] : []),
          ])}
          className={styles.zeckEditPage__content}
          onScroll={onScroll}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default EditPage;
