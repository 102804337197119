import {
  Block,
  BulletedListItemBlock,
  isTextBlock,
  TextBlock,
} from 'editor-content/Block.js';
import {
  BlockSelection,
  indexIsInSelection,
} from '../../../editor/selection/BlockSelection.ts';
import createZeckEditorTurnintoables from './editableBlocks/createZeckEditorTurnIntoables.ts';
import BlockFormattingMenu from '../../../design-system/organisms/BlockFormattingMenu.tsx';

type MultiblockSelectionMenuProps = {
  selection: BlockSelection;
  blocks: Block[];
  onTurnInto: (type: TextBlock['type']) => void;
  onIndent: (indent: -1 | 1) => void;
};

export default function MultiblockSelectionMenu({
  selection,
  blocks,
  onTurnInto,
  onIndent,
}: MultiblockSelectionMenuProps) {
  const selectedBlocks = blocks.filter((_, i) =>
    indexIsInSelection(selection, i),
  );
  const isText = selectedBlocks.every((block) => isTextBlock(block));

  if (!isText) return null;

  const allBulletListItemBlocks = selectedBlocks.every(
    (block): block is BulletedListItemBlock =>
      block.type === 'bulleted-list-item',
  );

  const canDedent =
    allBulletListItemBlocks &&
    !selectedBlocks.every((block) => block.indent === 0);
  const canIndent =
    allBulletListItemBlocks &&
    !selectedBlocks.every((block) => block.indent === 1);

  return (
    <BlockFormattingMenu
      {...{
        turnIntoables: createZeckEditorTurnintoables(onTurnInto),
        onDedent: canDedent ? () => onIndent(-1) : undefined,
        onIndent: canIndent ? () => onIndent(1) : undefined,
      }}
    />
  );
}
