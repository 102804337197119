import { useRef } from 'react';

function useCommentsSectionScrollSync() {
  const commentSectionsRef = useRef<Record<string, HTMLElement | null>>({});
  const setCommentSectionRef =
    (sectionId: string) => (el: HTMLElement | null) => {
      commentSectionsRef.current = {
        ...commentSectionsRef.current,
        [sectionId]: el,
      };
    };

  const sectionsContentRef = useRef<Record<string, HTMLElement | null>>({});
  const setSectionContentRef =
    (sectionId: string) => (el: HTMLElement | null) => {
      sectionsContentRef.current = {
        ...sectionsContentRef.current,
        [sectionId]: el,
      };
    };

  const zeckScrollContainerRef = useRef<HTMLElement | null>(null);

  const scrollSectionIntoViewIfNeeded = (sectionId: string) => {
    const sectionContentEl = sectionsContentRef.current[sectionId];
    const scrollContainerEl = zeckScrollContainerRef.current;
    if (!sectionContentEl || !scrollContainerEl) return;

    const scrollContainerRect = scrollContainerEl.getBoundingClientRect();
    const sectionContentRect = sectionContentEl.getBoundingClientRect();

    const top = scrollContainerRect.top + 200;
    const bottom = scrollContainerRect.bottom - 80;
    if (
      (sectionContentRect.bottom > top && sectionContentRect.bottom < bottom) ||
      (sectionContentRect.top > top && sectionContentRect.top < bottom) ||
      (sectionContentRect.top < bottom && sectionContentRect.bottom > top)
    )
      return;

    sectionContentEl.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });
  };

  const scrollCommentsSectionIntoView = (sectionId: string) => {
    commentSectionsRef.current[sectionId]?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  const setZeckScrollContainerRef = (el: HTMLElement) =>
    (zeckScrollContainerRef.current = el);

  return {
    setCommentSectionRef,
    setSectionContentRef,
    setZeckScrollContainerRef,
    scrollSectionIntoViewIfNeeded,
    scrollCommentsSectionIntoView,
  };
}

export default useCommentsSectionScrollSync;
