import Button from '../../design-system/atoms/Button.tsx';

const TestPage = () => {
  return (
    <>
      <Button
        size="large"
        color="danger"
        onClick={() => {
          throw new Error('UI Error');
        }}
      >
        UI Error
      </Button>
    </>
  );
};

export default TestPage;
