import { Company, User } from '../../../types.ts';
import { useState } from 'react';
import FileFormattingMenu from '../../../design-system/organisms/FileFormattingMenu.tsx';
import FileUploadModal from '../../../editor/domFacing/components/FileUploadModal.tsx';
import HoverNextToPoint from '../../../domHelpers/hoverNextTo/HoverNextToPoint.tsx';
import SelectionCommentWithActions from './comments/SelectionCommentWithActions.ts';
import { isSameSelection } from '../../../SelectionComment.ts';
import CommentsMenu from './comments/components/CommentsMenu.tsx';
import { FileBlock } from 'editor-content/FileBlock.js';
import { CommentContentNode } from 'editor-content/CommentContent.js';

type FileFormattingExperienceProps = {
  block: FileBlock;
  getEl: () => HTMLElement | undefined;
  company: Pick<Company, 'id'>;
  onReplaceFile: (file: Pick<FileBlock, 'guid' | 'filename'>) => void;
  onDeleteFile: () => void;
  onAddSelectionComment: (
    blockId: string,
    commentText: CommentContentNode[],
  ) => Promise<void>;
  user: User;
  selectionComments: SelectionCommentWithActions[];
  zeckId: string;
  sectionId: string;
};

type BlockInteractiveRenderState = 'formatting' | 'replacing' | 'commenting';

const FileFormattingExperience: React.VFC<FileFormattingExperienceProps> = ({
  block,
  getEl,
  company,
  onReplaceFile,
  onDeleteFile,
  onAddSelectionComment,
  user,
  selectionComments,
  zeckId,
  sectionId,
}) => {
  const [interactiveState, setInteractiveState] =
    useState<BlockInteractiveRenderState>('formatting');

  return (
    <>
      {interactiveState === 'formatting' && (
        <HoverNextToPoint
          viewportPolicy="none"
          containerStyles={{ zIndex: 'initial' }}
          getPoint={(popoverEl) => {
            const targetEl = getEl();
            if (!targetEl) return [0, 0];

            const targetRect = targetEl.getBoundingClientRect();
            const popoverRect = popoverEl.getBoundingClientRect();
            return [
              targetRect.x + targetRect.width / 2 - popoverRect.width / 2,
              targetRect.y - popoverRect.height - 16,
            ];
          }}
          usePortal
        >
          <FileFormattingMenu
            {...{
              onClickReplace: () => {
                setInteractiveState('replacing');
              },
              onClickDelete: () => {
                onDeleteFile();
              },
              onClickComment: () => {
                setInteractiveState('commenting');
              },
            }}
          />
        </HoverNextToPoint>
      )}
      {interactiveState === 'commenting' && (
        <HoverNextToPoint
          usePortal
          getPoint={(popoverEl) => {
            const targetEl = getEl();
            if (!targetEl) return [0, 0];

            const targetRect = targetEl.getBoundingClientRect();
            const popoverRect = popoverEl.getBoundingClientRect();
            return [
              targetRect.x + targetRect.width / 2 - popoverRect.width / 2,
              targetRect.y - popoverRect.height + 24,
            ];
          }}
        >
          <CommentsMenu
            autofocus
            user={user}
            comments={selectionComments.filter((selectionComment) =>
              isSameSelection(selectionComment, block),
            )}
            onPostComment={(content) =>
              onAddSelectionComment(block.id, content)
            }
            companyId={company.id}
            zeckId={zeckId}
            sectionId={sectionId}
          />
        </HoverNextToPoint>
      )}
      <FileUploadModal
        isOpen={interactiveState === 'replacing'}
        company={company}
        onUploadSuccess={(file) => {
          setInteractiveState('formatting');
          onReplaceFile(file);
        }}
        onRequestClose={() => {
          setInteractiveState('formatting');
        }}
      />
    </>
  );
};

export default FileFormattingExperience;
