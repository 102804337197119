import { TextNode } from 'editor-content/TextNode.ts';
import replaceSelectionWith from './replaceSelectionWith.js';
import { createParagraphBlock, isTextBlock } from 'editor-content/Block.ts';
import getEndOfBlock from '../../../../editor/blocks/textBlocksStrategies/getEndOfBlock.js';
import { textSelection } from '../../../../editor/selection/TextSelection.js';
import textBlockReplaceSelectedContentStrategy from '../../../../editor/blocks/textBlocksStrategies/textBlockReplaceSelectedContentStrategy.js';
import { BodyStateSelected } from './BodyEditor.js';

function pasteText(
  initialState: BodyStateSelected,
  textNodes: TextNode[],
): BodyStateSelected | void {
  return replaceSelectionWith(initialState, {
    textSelection(selectedBlock, selection) {
      if (!isTextBlock(selectedBlock)) {
        const newBlock = createParagraphBlock(textNodes);
        const newBlockSelection = getEndOfBlock(newBlock);

        return {
          contentSubset: [newBlock],
          selection: textSelection(0, newBlockSelection),
        };
      } else {
        return textBlockReplaceSelectedContentStrategy(
          selectedBlock,
          selection,
          { type: 'text', content: textNodes },
        );
      }
    },
    blockSelection() {
      const newBlock = createParagraphBlock(textNodes);
      const newBlockSelection = getEndOfBlock(newBlock);
      return {
        contentSubset: [newBlock],
        selection: textSelection(0, newBlockSelection),
      };
    },
  });
}

export default pasteText;
