import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ContentSelection, {
  contentSelection,
} from '../../../editor/selection/contentSelection/ContentSelection.ts';
import BlockEditable from './BlockEditable.tsx';
import useZeckEditor from './useZeckEditor.ts';
import { Block } from 'editor-content/Block.js';
import styles from './ZeckEditable.module.scss';
import zeckStyles from '../../../design-system/zeck/ZeckStyles.module.scss';
import TitleEditableWithSelection from './editableBlocks/TitleEditableWithSelection.tsx';
import { getSpacing } from './blockPreprocessing/addSpacing.ts';
import EditAppearanceButton from '../edit/EditAppearanceButton.tsx';
import { EditorContent } from '../edit/useEditorState.tsx';
import ZeckEditorSelection, {
  getBodySelection,
  getHeadlineSelection,
  getTitleSelection,
} from './ZeckEditorSelection.ts';
import HeadlineEditableWithKeyboard from './editableBlocks/HeadlineEditableWithKeyboard.tsx';
import {
  isTextSelection,
  textSelection,
} from '../../../editor/selection/TextSelection.ts';
import useElementAndDataArray from '../../../junkDrawer/useElementAndDataArray.ts';
import useMouseSelectionOnEditor from '../../../editor/selection/useMouseSelectionOnEditor.ts';
import MultiblockSelectionMenu from './MultiblockSelectionMenu.tsx';
import HoverNextToPoint from '../../../domHelpers/hoverNextTo/HoverNextToPoint.tsx';
import { getRectFromEl } from '../../../domHelpers/Rect.ts';
import { subtractVector } from '../../../domHelpers/Point.ts';
import useUndoRedo from './useUndoRedo.ts';
import mergeRefs from '../../../junkDrawer/mergeRefs.ts';
import { HeadlineLine } from '../../../design-system/zeck/Headline.tsx';
import SelectionCommentsMenu from './comments/components/SelectionCommentsMenu.js';
import BlockFormattingMenu from './BlockFormattingMenu.js';
import useHighlightHover from './comments/components/useHighlightHover.js';
import BlockCommentsExperience from './comments/components/BlockCommentsExperience.js';
import DetectsOutsideEditorClick from '../../../editor/domFacing/components/DetectsOutsideEditorClick.js';
import SelectionCommentWithActions from './comments/SelectionCommentWithActions.js';
import withBlockKeys from './withBlockKeys.js';
import useUndoRedoKeybindings from './useUndoRedoKeybindings.js';
import { CommentContentNode } from 'editor-content/CommentContent.js';
import { Editor } from 'editor-content/TextNode.js';
import {
  indexIsInSelection,
  isBlockSelection,
} from '../../../editor/selection/BlockSelection.ts';
import { renderGroupListRenderables } from './blockPreprocessing/groupListRenderables.tsx';
import { HydratedBlock } from '../../../types/HydratedBlock.ts';
import TextSelectionFormattingMenu from './editableBlocks/TextSelectionFormattingMenu.tsx';
import EditorLayout from '../../../design-system/zeck/EditorLayout.tsx';
import useSetBrowserSelectionWhenNull from '../../../editor/useSetBrowserSelectionWhenNull.ts';
import {
  ZeckFinalizeVoteCapability,
  ZeckPrevoteCapability,
} from '../voting/VoteCapability.ts';
import ResetPrevotesExperience from './ResetPrevotesExperience.js';
import { IntegrationListItemData } from '../../../api/endpoints/createIntegrationApi.ts';
import Linkable from 'editor-content/html/Linkable.ts';
import { Company, CompanyLite, User } from '../../../types.ts';
import CopyPasteExperience from './CopyPasteExperience.js';
import AiControls from '../edit/AiControls/AiControls.tsx';
import AddBlockExperienceZeck from '../../../editor/addBlock/zeck/AddBlockExperienceZeck.js';
import useFancyNav from './FancyNav/useFancyNav.js';
import useSelectionComments from './comments/useSelectionComments.js';
import useKeepSelectionInView from '../scrolling/useKeepSelectionInView.js';
import useExclusiveBlockSelection from './selection/useExclusiveBlockSelection.js';
import cx from 'classnames';

type ZeckEditableProps = {
  editorContent: EditorContent;
  setEditorContent(newContent: EditorContent): void;
  subscribeToEditTitle: (callback: () => void) => () => void;
  onEditAppearance(): void;
  integrationData: IntegrationListItemData;
  selectionComments: SelectionCommentWithActions[];
  zeckId: string;
  sectionId: string;
  company: Company | CompanyLite;
  user: User;
  createSelectionComment: (
    highlightId: string,
    content: CommentContentNode[],
    selection: Block | Editor.Highlight,
  ) => Promise<void>;
  zeckPrevoteCapability: ZeckPrevoteCapability | null;
  zeckFinalizeVoteCapability: ZeckFinalizeVoteCapability | null;
  prevotedBlockIds: string[];
  linkables: Linkable[];
  getScrollContainer: () => HTMLElement | null;
};

const ZeckEditable = ({
  editorContent,
  setEditorContent,
  subscribeToEditTitle,
  onEditAppearance,
  selectionComments,
  user,
  company,
  zeckId,
  sectionId,
  integrationData,
  createSelectionComment,
  zeckFinalizeVoteCapability,
  zeckPrevoteCapability,
  prevotedBlockIds,
  linkables,
  getScrollContainer,
}: ZeckEditableProps) => {
  const [editorSelection, setEditorSelection] =
    useState<ZeckEditorSelection>(null);

  useEffect(() => {
    return subscribeToEditTitle(() => {
      setEditorSelection({
        target: 'title',
        anchorOffset: 0,
        focusOffset: editorContent.title.length,
      });
    });
  }, [subscribeToEditTitle, editorContent.title]);

  const { undo, redo, setValue, value } = useUndoRedo(
    useMemo(
      () => ({
        content: editorContent,
        selection: editorSelection,
      }),
      [editorContent, editorSelection],
    ),
    useCallback(
      (newValue) => {
        setEditorContent(newValue.content);
        setEditorSelection(newValue.selection);
      },
      [setEditorSelection, setEditorContent],
    ),
  );

  const {
    bodyContent,
    bodySelection,
    onNavLeft,
    onNavRight,
    onSelectUp,
    onSelectDown,
    onBackspace,
    onEnter,
    onDelete,
    onPasteBlocks,
    onPasteText,
    onPastePlaintext,
    onPasteImage,
    onSelectTitle,
    onSelectHeadline,
    onSelectBody,
    onChangeTitle,
    onChangeHeadline,
    onAddBlockAtEnd,
    onToggleFormat,
    onAddLink,
    onSetImageWidth,
    onSetImageAlign,
    onReplaceImage,
    onReplaceFile,
    onReplaceTable,
    onTurnInto,
    onIndent,
    onEditBlock,
    onInsertAiContentAbove,
    onCut,
    onCopy,
    AddBlock,
  } = useZeckEditor(
    value.content,
    value.selection,
    useCallback(
      (newContent, newSelection) => {
        setValue({
          content: newContent,
          selection: newSelection,
        });
      },
      [setValue],
    ),
  );

  const documentRef = useRef<HTMLDivElement>(null);

  const blocksWithEl = useElementAndDataArray(bodyContent);

  useUndoRedoKeybindings(undo, redo);

  const {
    onMouseDown,
    onMouseMove,
    onMouseUp,
    isSelecting: isMouseSelecting,
  } = useMouseSelectionOnEditor(documentRef, blocksWithEl, onSelectBody);

  const fancyNavBehavior = useFancyNav({
    blocksWithEl,
    bodySelection,
    onSelectTitle,
    onSelectHeadline,
    onSelectBody,
    onNavLeft,
    onNavRight,
  });

  const blocksWithKey = withBlockKeys(
    blocksWithEl.map(({ data: block, ...etc }) => ({
      ...etc,
      block,
    })),
  );

  const selectionCommentsBehavior = useSelectionComments({
    editorState: value,
    setEditorState: setValue,
    selectionComments,
    createSelectionComment,
  });

  useHighlightHover();

  useSetBrowserSelectionWhenNull(value.selection);

  const leftGutterRef = useRef<HTMLDivElement>(null);
  useKeepSelectionInView(
    blocksWithEl,
    getBodySelection(value.selection),
    isMouseSelecting,
    getScrollContainer,
  );

  const editorEventListenerRef = useExclusiveBlockSelection(
    getBodySelection(value.selection),
  );

  const onConfigureComplexBlock = useCallback(() => {
    onSelectBody(null);
  }, [onSelectBody]);

  const onReplaceNewBlock: React.ComponentProps<
    typeof AddBlockExperienceZeck
  >['onReplaceNewBlock'] = (blocks, blockId) => {
    AddBlock.replaceNewBlock(blocks, blockId);

    if (!editorSelection) {
      const blockIndex = editorContent.body.findIndex(
        (block) => block.id === blockId,
      );
      onSelectBody(textSelection(blockIndex, contentSelection(0)));
    }
  };

  const handleOutsideClick = () => setEditorSelection(null);
  return (
    <>
      <DetectsOutsideEditorClick<HTMLDivElement>
        onOutsideClick={handleOutsideClick}
      >
        {(documentContainerRef) => (
          <EditorLayout
            className={cx(zeckStyles.zeck, styles.zeckEditable)}
            onClickGutter={handleOutsideClick}
            leftGutterRef={leftGutterRef}
            ref={mergeRefs([
              documentContainerRef,
              documentRef,
              editorEventListenerRef,
            ])}
            {...{
              tabIndex: 0,
              onKeyDown(e) {
                switch (e.key) {
                  case 'Backspace':
                    onBackspace() && e.preventDefault();
                    return;
                  case 'Delete':
                    onDelete() && e.preventDefault();
                    return;
                  case 'Enter':
                    if (e.getModifierState('Shift')) return;
                    onEnter();
                    return;
                  case 'ArrowUp':
                    if (e.getModifierState('Shift')) onSelectUp();
                    return;
                  case 'ArrowDown':
                    if (e.getModifierState('Shift')) onSelectDown();
                    return;
                }
              },
              onMouseDown,
              onMouseMove,
              onMouseUp,
            }}
          >
            <SelectionCommentsMenu
              documentRef={documentRef}
              selectionComments={selectionCommentsBehavior.selectionComments}
              companyId={company.id}
              zeckId={zeckId}
              sectionId={sectionId}
              onAddSelectionComment={selectionCommentsBehavior.onAddToExisting}
              editorContent={editorContent}
              editorSelection={editorSelection}
              user={user}
            />
            <BlockFormattingMenu
              blocksWithEl={blocksWithEl}
              selection={editorSelection}
              company={company}
              onSetImageWidth={onSetImageWidth}
              onSetImageAlign={onSetImageAlign}
              onReplaceImage={onReplaceImage}
              onReplaceTable={onReplaceTable}
              onDeleteImage={onBackspace}
              onReplaceFile={onReplaceFile}
              onDeleteFile={onBackspace}
              selectionComments={selectionComments}
              zeckId={zeckId}
              sectionId={sectionId}
              onAddSelectionComment={selectionCommentsBehavior.onAddToExisting}
              user={user}
              integrationData={integrationData}
            />
            {isBlockSelection(bodySelection) && (
              <HoverNextToPoint
                usePortal
                viewportPolicy="none"
                getPoint={(childElement) => {
                  const el =
                    blocksWithEl[
                      Math.min(
                        bodySelection.anchorIndex,
                        bodySelection.focusIndex,
                      )
                    ]?.getEl();

                  if (!el) {
                    return [NaN, NaN];
                  }

                  const rect = getRectFromEl(el);
                  const { height, width } =
                    childElement.getBoundingClientRect();

                  return subtractVector(
                    [(rect[0][0] + rect[1][0]) / 2, rect[0][1]],
                    [width / 2, height + 8],
                  );
                }}
              >
                <MultiblockSelectionMenu
                  selection={bodySelection}
                  blocks={bodyContent}
                  onTurnInto={onTurnInto}
                  onIndent={onIndent}
                />
              </HoverNextToPoint>
            )}
            <div className={styles.zeckEditable__editAppearanceButtonContainer}>
              <EditAppearanceButton
                onClick={onEditAppearance}
                className={styles.zeckEditable__editAppearanceButton}
              />
            </div>
            <TitleEditableWithSelection
              {...{
                className: styles.zeckEditable__sectionTitle,
                'data-testid': 'title-editable',
                ref: fancyNavBehavior.title.ref,
                value: editorContent.title,
                onChange: (content, selection) =>
                  onChangeTitle({ content, selection }),
                onSelect: onSelectTitle,
                selection: getTitleSelection(editorSelection),
                onNavRight: onNavRight,
                onPressEnter: onEnter,
                onNavLeft() {
                  return;
                },
                onNavUp: fancyNavBehavior.title.onNavUp,
                onNavDown: fancyNavBehavior.title.onNavDown,
              }}
            />
            <HeadlineEditableWithKeyboard
              {...{
                ref: fancyNavBehavior.headline.ref,
                'data-testid': 'block-1',
                value: editorContent.headline,
                onChange: (content, selection) =>
                  onChangeHeadline({ content, selection }),
                linkables,
                selection: getHeadlineSelection(editorSelection),
                onPressEnter: onEnter,
                onNavUp: fancyNavBehavior.headline.onNavUp,
                onNavDown: fancyNavBehavior.headline.onNavDown,
                onNavLeft,
                onNavRight,
                onSelectOut: () => null,
                onSelect: onSelectHeadline,
                onToggleFormat,
                onAddLink,
                onAddSelectionComment: selectionCommentsBehavior.onAdd,
                companyId: company.id,
                zeckId,
                sectionId: sectionId,
                onTurnInto,
              }}
            />
            <HeadlineLine />

            <ResetPrevotesExperience
              onEditBlock={onEditBlock}
              prevotedBlockIds={prevotedBlockIds}
            >
              {(onEditBlock) =>
                renderGroupListRenderables(
                  blocksWithKey.map((item) => ({
                    ...item,
                    data: { block: item.block },
                  })),

                  ({
                    block,
                    setEl,
                    getEl,
                    key,
                    spacing,
                    groupedRenderableType,
                    i,
                  }) => {
                    const blockScopedSelection =
                      bodySelection &&
                      isTextSelection(bodySelection) &&
                      bodySelection.index === i
                        ? bodySelection.offset
                        : null;

                    return (
                      <Fragment key={key}>
                        <span
                          className={
                            styles.zeckEditable__blockCommentButtonWrapper
                          }
                        >
                          <BlockCommentsExperience
                            {...{
                              block,
                              user,
                              getEl,
                              selectionComments,
                              companyId: company.id,
                              zeckId,
                              sectionId,
                              onAddSelectionComment:
                                selectionCommentsBehavior.onAddToExisting,
                            }}
                          />
                        </span>
                        <BlockEditable
                          {...{
                            className:
                              groupedRenderableType === 'item'
                                ? getSpacing(spacing)
                                : '',
                            block,
                            linkables,
                            ref: setEl,
                            'data-testid': `block-${i + 2}`,

                            formattingMenu: (
                              <TextSelectionFormattingMenu
                                linkables={linkables}
                                block={block}
                                selection={blockScopedSelection}
                                companyId={company.id}
                                onIndent={onIndent}
                                onToggleFormat={onToggleFormat}
                                onAddLink={onAddLink}
                                onTurnInto={onTurnInto}
                                onAddSelectionComment={
                                  selectionCommentsBehavior.onAdd
                                }
                                zeckId={zeckId}
                                sectionId={sectionId}
                              />
                            ),

                            isInBlockSelection:
                              !!bodySelection &&
                              !isTextSelection(bodySelection) &&
                              indexIsInSelection(bodySelection, i),

                            selection: blockScopedSelection,
                            onChange(
                              newBlock: HydratedBlock,
                              contentSelection: ContentSelection,
                            ) {
                              onEditBlock(i, newBlock, contentSelection);
                            },
                            onSelect(contentSelection: ContentSelection) {
                              if (isMouseSelecting) return;
                              onSelectBody({
                                index: i,
                                offset: contentSelection,
                              });
                            },
                            onEnter,
                            onNavRight,
                            onNavLeft,
                            onNavUp: fancyNavBehavior.body.onNavUp,
                            onNavDown: fancyNavBehavior.body.onNavDown,
                            onSelectOut() {
                              onSelectUp();
                            },
                            zeckFinalizeVoteCapability,
                            zeckPrevoteCapability,
                          }}
                        />
                      </Fragment>
                    );
                  },
                )
              }
            </ResetPrevotesExperience>

            <div
              data-testid="add-block-end-click-area"
              style={{ height: 400 }}
              onClick={onAddBlockAtEnd}
            />

            <CopyPasteExperience
              {...{
                onPasteBlocks,
                onPasteText,
                onPastePlaintext,
                onPasteImage,
                onCut,
                onCopy,
                linkables,
                companyId: company.id,
              }}
            />
            <AddBlockExperienceZeck
              blocksWithEl={blocksWithEl}
              leftGutterRef={leftGutterRef}
              onAddNewBlock={AddBlock.addNewBlock}
              onReplaceNewBlock={onReplaceNewBlock}
              onConfigureComplexBlock={onConfigureComplexBlock}
              company={company}
              integrationData={integrationData}
            />
          </EditorLayout>
        )}
      </DetectsOutsideEditorClick>

      <AiControls
        editorContent={editorContent}
        sectionId={sectionId}
        userId={user.id}
        companyId={company.id}
        onInsertAbove={(newContent) => {
          onInsertAiContentAbove(newContent, linkables);
        }}
        linkables={linkables}
      />
    </>
  );
};

export default ZeckEditable;
