import { useEffect, useRef } from 'react';
import Point from '../Point.ts';
import { HoverPortal } from './HoverLayer.tsx';

const VIEWPORT_PADDING = 16;

export type ViewportPolicy = 'position' | 'size' | 'none';
type HoverNextToPointProps = {
  children: React.ReactNode;
  viewportPolicy?: ViewportPolicy;
  getPoint(childElement: HTMLElement): Point | null;
  usePortal?: boolean;
  onMouseLeave?: () => void;
  containerStyles?: React.CSSProperties;
};

const withWindowBound =
  (rect: DOMRect) =>
  ([x, y]: Point): Point => {
    return [
      Math.max(
        0,
        Math.min(x, window.innerWidth - rect.width - VIEWPORT_PADDING),
      ),
      Math.max(
        0,
        Math.min(y, window.innerHeight - rect.height - VIEWPORT_PADDING),
      ),
    ];
  };
const withoutWindowBound =
  () =>
  ([x, y]: Point): Point => {
    return [x, y];
  };

const autosize = () => 'auto';
const resizeToWindow = (point: Point) =>
  `${window.innerHeight - point[1] - VIEWPORT_PADDING}px`;

const pointToTransform = ([x, y]: Point) => `translate(${x}px, ${y}px)`;

const HoverNextToPoint = ({
  children,
  viewportPolicy = 'position',
  getPoint,
  usePortal,
  containerStyles = {},
  onMouseLeave = () => {
    return;
  },
}: HoverNextToPointProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let animationFrameHandle: number;
    const withViewportPositionPolicy =
      viewportPolicy == 'position' ? withWindowBound : withoutWindowBound;
    const viewportSizePolicy =
      viewportPolicy == 'size' ? resizeToWindow : autosize;

    const anim = () => {
      if (containerRef.current) {
        const point = getPoint(containerRef.current);

        if (point) {
          containerRef.current.style.visibility = 'visible';
          containerRef.current.style.transform = pointToTransform(
            withViewportPositionPolicy(
              containerRef.current.getBoundingClientRect(),
            )(point),
          );
          containerRef.current.style.height = viewportSizePolicy(point);
        } else {
          containerRef.current.style.visibility = 'hidden';
        }
      }
      animationFrameHandle = requestAnimationFrame(anim);
    };

    anim();

    return () => {
      cancelAnimationFrame(animationFrameHandle);
    };
  }, [containerRef, getPoint, viewportPolicy]);

  const insides = (
    <div
      {...{
        onMouseLeave: onMouseLeave,
        ref: containerRef,
        className: 'tooltip-container',
        style: {
          position: 'fixed',
          top: 0,
          left: 0,
          transform: `translate(-100%, -100%)`,
          margin: 0,
          zIndex: 10,
          ...containerStyles,
          // transition: 'transform 100ms ease-out'
        },
      }}
    >
      {children}
    </div>
  );

  if (usePortal) {
    return <HoverPortal>{insides}</HoverPortal>;
  }

  return insides;
};

export default HoverNextToPoint;
