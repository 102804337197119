import ContentSelection from '../../../../editor/selection/contentSelection/ContentSelection.js';
import EditorData from '../EditorData.js';
import { EditorStateGeneric } from '../../../../editor/EditorStateGeneric.js';
import {
  isBlockSelection,
  isTextSelection,
} from '../../../../editor/EditorSelection.js';
import { HydratedBlock } from '../../../../types/HydratedBlock.js';
import splitContentByBlockSelection from '../../../../editor/selection/splitContentByBlockSelection.js';
import getSelectedBlock from './getSelectedBlock.js';
import { isTextBlock } from 'editor-content/Block.js';
import textBlockGetSelectedContentStrategy from '../../../../editor/blocks/textBlocksStrategies/textBlockGetSelectedContentStrategy.js';

const copy =
  <BlockType>(
    generateBlockEditor: (b: BlockType) => {
      copy: (selection: ContentSelection) => EditorData<BlockType> | void;
    },
  ) =>
  (
    initialState: EditorStateGeneric<BlockType>,
  ): EditorData<BlockType> | void => {
    const { content, selection } = initialState;

    // this is basically the same as `replaceSelectionWith` but with a return value
    if (isBlockSelection(selection)) {
      const [, selectedBlocks] = splitContentByBlockSelection(
        content,
        selection,
      );

      return { type: 'block', content: selectedBlocks };
    }

    if (isTextSelection(selection)) {
      const currentBlock = getSelectedBlock(content, selection);
      if (!currentBlock) return;

      return generateBlockEditor(currentBlock).copy(selection.offset);
    }

    return;
  };

export const zeckEditorCopy = copy<HydratedBlock>((block) => {
  if (isTextBlock(block)) {
    return {
      copy: (selection) => {
        return textBlockGetSelectedContentStrategy(block, selection);
      },
    };
  }
  return {
    copy: () => {
      return { type: 'block', content: [block] };
    },
  };
});
