import React from 'react';
import { CommentsState } from './useComments/useComments.js';
import { PublishedSection } from 'api-client/types.js';
import cx from 'classnames';
import styles from './CommentsSidebar.module.scss';
import IconButton from '../../../../../design-system/atoms/IconButton.js';
import CommentsActionBar from './commentsActionBar/CommentsActionBar.js';
import { motion } from 'framer-motion';
import { CommentsSection } from './commentsSection/CommentsSection.js';
import CommentsSidebarModalLayer from '../CommentsSidebarModalLayer.js';

const CommentsSidebar: React.FC<{
  isFullscreenMode: boolean;
  commentsState: NonNullable<CommentsState>;
  publishedSections: PublishedSection[];
}> = ({ isFullscreenMode, commentsState, publishedSections }) => (
  <div
    className={cx(
      styles.commentsSidebar,
      isFullscreenMode && styles.commentsSidebar_fullscreen,
    )}
  >
    <div className={styles.commentsSidebar__header}>
      <IconButton
        aria-label="hide-comments"
        name="doubleChevronRight"
        onClick={commentsState.closeComments}
        className={styles.commentsSidebar__closeButton}
      />
      <span>Comments ({commentsState.totalCommentCount})</span>
    </div>
    <CommentsActionBar commentsState={commentsState} />

    <div className={styles.commentsSidebar__main}>
      {publishedSections.map((publishedSection) => (
        <motion.div
          layout
          key={publishedSection.sectionId}
          transition={{ duration: 0.2 }}
          style={{ backgroundColor: 'white' }}
        >
          <CommentsSection
            key={publishedSection.sectionId}
            state={commentsState.forSection(publishedSection)}
          />
        </motion.div>
      ))}
    </div>
    <CommentsSidebarModalLayer />
  </div>
);

export default CommentsSidebar;
