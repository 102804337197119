import { makeMakeApiRequest } from '../makeMakeApiRequest.js';
import { Comment, CommentReply } from '../../types/Comment.js';
import { CommentContent } from 'editor-content/CommentContent.js';

type DirectMessage = Comment & { type: 'DirectMessage' };
type DirectMessageReply = CommentReply & { type: 'DirectMessageReply' };

const createCommentApi = (
  makeLambdaApiRequest: ReturnType<typeof makeMakeApiRequest>,
  accessToken: string | null,
) => {
  const updateComment = async (
    commentId: string,
    content: CommentContent,
  ): Promise<Comment> => {
    return await makeLambdaApiRequest(`/comment/${commentId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ content }),
    });
  };

  const updateDirectMessage = async <
    MessageType extends DirectMessage | DirectMessageReply,
  >(
    comment: Pick<MessageType, 'content' | 'id'>,
  ): Promise<MessageType> => {
    return await makeLambdaApiRequest(`/direct-message/${comment.id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ content: comment.content }),
    });
  };
  const deleteComment = async (commentId: string): Promise<void> => {
    await makeLambdaApiRequest(`/comment/${commentId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
  };
  const deleteDirectMessage = async (
    directMessageId: string,
  ): Promise<void> => {
    await makeLambdaApiRequest(`/direct-message/${directMessageId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
  };
  const postCommentReply = async (
    commentId: string,
    content: CommentContent,
  ): Promise<CommentReply> => {
    return await makeLambdaApiRequest(`/comment/${commentId}/comment-reply`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ content }),
    });
  };
  const postDirectMessageReply = async (
    directMessageId: string,
    content: CommentContent,
  ): Promise<DirectMessageReply> => {
    const directMessage = await makeLambdaApiRequest(
      `/direct-message/${directMessageId}/reply`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ content }),
      },
    );
    return directMessage;
  };
  const updateCommentReply = async (
    commentReplyId: string,
    content: CommentContent,
  ): Promise<CommentReply> => {
    return await makeLambdaApiRequest(`/comment-reply/${commentReplyId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ content }),
    });
  };
  const deleteCommentReply = async (commentReplyId: string): Promise<void> => {
    return await makeLambdaApiRequest(`/comment-reply/${commentReplyId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
  };
  const postComment = async (
    sectionId: string,
    content: CommentContent,
  ): Promise<Comment> => {
    const comment = await makeLambdaApiRequest(
      `/section/${sectionId}/comment`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ content }),
      },
    );
    return comment;
  };
  const postDirectMessage = async (
    sectionId: string,
    content: CommentContent,
  ): Promise<DirectMessage> => {
    const comment = await makeLambdaApiRequest(
      `/section/${sectionId}/direct-message`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ content }),
      },
    );
    return comment;
  };

  return {
    async getZeckComments(
      zeckId: string,
    ): Promise<{ [sectionId: string]: Comment[] }> {
      const commentsBySection: {
        [sectionId: string]: (Comment | DirectMessage)[];
      } = await makeLambdaApiRequest(`/zeck/${zeckId}/comment`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return commentsBySection;
    },

    createComment: async (
      sectionId: string,
      content: CommentContent,
      asDirectMessage: boolean,
    ) => {
      if (asDirectMessage) {
        return await postDirectMessage(sectionId, content);
      } else {
        return await postComment(sectionId, content);
      }
    },

    resolveComment: async (
      commentId: string,
      commentType: Comment['type'],
    ): Promise<void> => {
      await makeLambdaApiRequest(`/comment/${commentId}/tag`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ tag: 'resolved', type: commentType }),
      });
    },
    unresolveComment: async (
      commentId: string,
      commentType: Comment['type'],
    ): Promise<void> => {
      await makeLambdaApiRequest(`/comment/${commentId}/tag`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ tag: 'resolved', type: commentType }),
      });
    },
    // we could consider just exposing an add/remove tag to this layer
    // but the goal here is to just hide the tag implementation from
    // as much of the UI as possible, but it might get absurd if we have
    // 3+ types of tags.
    starComment: async (
      commentId: string,
      commentType: Comment['type'],
    ): Promise<void> => {
      await makeLambdaApiRequest(`/comment/${commentId}/tag`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ tag: 'starred', type: commentType }),
      });
    },
    unStarComment: async (
      commentId: string,
      commentType: Comment['type'],
    ): Promise<void> => {
      await makeLambdaApiRequest(`/comment/${commentId}/tag`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ tag: 'starred', type: commentType }),
      });
    },

    updateComment: async (
      comment: Comment,
      newContent: CommentContent,
    ): Promise<Comment> => {
      switch (comment.type) {
        case 'Comment': {
          return await updateComment(comment.id, newContent);
        }
        case 'DirectMessage': {
          return await updateDirectMessage<DirectMessage>({
            id: comment.id,
            content: newContent,
          });
        }
      }
    },

    deleteComment: async (comment: Comment): Promise<void> => {
      switch (comment.type) {
        case 'Comment': {
          return await deleteComment(comment.id);
        }
        case 'DirectMessage': {
          return await deleteDirectMessage(comment.id);
        }
      }
    },

    createCommentReply: async (
      comment: Comment,
      replyContent: CommentContent,
    ): Promise<CommentReply> => {
      switch (comment.type) {
        case 'Comment': {
          return await postCommentReply(comment.id, replyContent);
        }

        case 'DirectMessage': {
          return await postDirectMessageReply(comment.id, replyContent);
        }
      }
    },

    updateCommentReply: async (
      reply: CommentReply,
      newContent: CommentContent,
    ) => {
      switch (reply.type) {
        case 'CommentReply': {
          return await updateCommentReply(reply.id, newContent);
        }
        case 'DirectMessageReply': {
          return await updateDirectMessage<DirectMessageReply>({
            id: reply.id,
            content: newContent,
          });
        }
      }
    },

    deleteCommentReply: async (commentReply: CommentReply): Promise<void> => {
      switch (commentReply.type) {
        case 'CommentReply': {
          return await deleteCommentReply(commentReply.id);
        }
        case 'DirectMessageReply': {
          return await deleteDirectMessage(commentReply.id);
        }
      }
    },
  };
};

export default createCommentApi;
