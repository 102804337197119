import BaseAndExtent from '../selection/contentSelection/BaseAndExtent.js';
import ContentSelection from '../selection/contentSelection/ContentSelection.js';

export const getContentSelectionFromContainer = (
  containerElement: HTMLElement,
  fromBaseAndExtent: (
    baseAndExtent: BaseAndExtent,
    containerElement: HTMLElement,
  ) => ContentSelection,
) => {
  const domSelection = document.getSelection();

  if (!domSelection || !domSelection.anchorNode || !domSelection.focusNode) {
    return null;
  }

  if (!containerElement.contains(domSelection.anchorNode)) {
    return null;
  }

  return fromBaseAndExtent(
    {
      anchorNode: domSelection.anchorNode,
      anchorOffset: domSelection.anchorOffset,
      focusNode: domSelection.focusNode,
      focusOffset: domSelection.focusOffset,
    },
    containerElement,
  );
};
