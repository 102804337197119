import { Rect } from '../../domHelpers/Rect.js';
import Point from '../../domHelpers/Point.js';

const HORIZ_MARGIN = 16;

const getAddBlockButtonLocation = (
  blockRect: Rect,
  gutterRect: Rect,
  addBlockButtonSize: { width: number; height: number },
): Point | null => {
  const blockHeight = blockRect[1][1] - blockRect[0][1];

  const yCenteredWithinBlock =
    (blockRect[0][1] + blockRect[1][1]) / 2 - addBlockButtonSize.height / 2;

  const desiredPositionFromBlock: Point = [
    blockRect[0][0] - HORIZ_MARGIN - addBlockButtonSize.width,
    blockHeight < addBlockButtonSize.height + 16 // if block is too small, center
      ? yCenteredWithinBlock
      : blockRect[0][1],
  ];

  if (desiredPositionFromBlock[0] < gutterRect[0][0]) return null;

  const yPosWithinGutter = Math.min(
    gutterRect[1][0] - HORIZ_MARGIN - addBlockButtonSize.width,
    desiredPositionFromBlock[0],
  );
  return [yPosWithinGutter, desiredPositionFromBlock[1]];
};

export default getAddBlockButtonLocation;
