import { TextSelection } from './selection/TextSelection.js';
import {
  BlockEvent,
  CoordinatorEvent,
  PublicEditorEvent,
} from './EditorEvents.js';
import ContentSelection from './selection/contentSelection/ContentSelection.js';
import { TextNode } from 'editor-content/TextNode.js';
import EditorData from '../pages/zeck/editor/EditorData.js';
import { Block } from 'editor-content/Block.js';
import BaseBlock from 'editor-content/BaseBlock.js';
import { ContentPatch } from './ContentPatch.js';
import { AddBlockBlockEditorInterface } from './addBlock/AddBlockEditorActions.js';
import { PressDeleteBlockStrategies } from '../pages/zeck/editor/BodyEditor/pressDelete.js';
import { PressBackspaceBlockStrategies } from '../pages/zeck/editor/BodyEditor/pressBackspace.js';

export type BlockEditorClassToBlock<BlockEditorClass> =
  BlockEditorClass extends BaseBlockEditor<
    BaseBlock,
    infer BlockType extends Block
  >
    ? BlockType
    : never;

export type BlockEditorInterface<
  AvailableBlocks extends Block,
  BlockType extends Block,
> = {
  setEditorDispatch: (
    dispatch: (
      event:
        | PublicEditorEvent<AvailableBlocks | BlockType>
        | CoordinatorEvent<AvailableBlocks | BlockType>,
    ) => boolean,
  ) => void;

  getBlock: () => BlockType;

  isTextBlock: () => boolean;

  getSelectedContent: (selection: ContentSelection) => TextNode[] | void;

  replaceSelectedContent: (
    selection: ContentSelection,
    data: EditorData<AvailableBlocks>,
  ) => ContentPatch<AvailableBlocks[]> | void;

  replaceSelectedContentWithPlaintext: (
    selection: TextSelection,
    text: string,
  ) => boolean;

  toggleHighlight: (
    selection: ContentSelection,
  ) => ContentPatch<AvailableBlocks[]> | void;

  pressShiftArrowUp: (selection: ContentSelection) => boolean;
  pressShiftArrowDown: (selection: ContentSelection) => boolean;
  pressArrowUp: (isOnFirstLineOfBlock: () => boolean) => boolean;
  pressArrowDown: (isOnLastLineOfBlock: () => boolean) => boolean;
  pressEnter: (selection: ContentSelection) => ContentPatch<AvailableBlocks[]>;
  pressShiftEnter: (
    selection: ContentSelection,
  ) => ContentPatch<AvailableBlocks[]> | void;

  getEndOfBlockSelection: () => ContentSelection;

  // createFromBlock: (block: Block) => BlockType | void;

  dispatch: (
    event: BlockEvent<AvailableBlocks, BlockType>,
    selection: TextSelection,
  ) => boolean;

  Delete: PressDeleteBlockStrategies<BlockType>;

  Backspace: PressBackspaceBlockStrategies<BlockType>;
} & AddBlockBlockEditorInterface;

export abstract class BaseBlockEditor<
  AvailableBlock extends BaseBlock,
  BlockType extends Block,
> {
  editorDispatch:
    | ((
        event:
          | PublicEditorEvent<AvailableBlock | BlockType>
          | CoordinatorEvent<AvailableBlock | BlockType>,
      ) => boolean)
    | undefined;
  block: BlockType;
  createDefaultBlock: (content: TextNode[]) => AvailableBlock;

  constructor(
    block: BlockType,
    createDefaultBlock: (content: TextNode[]) => AvailableBlock,
  ) {
    this.block = block;
    this.createDefaultBlock = createDefaultBlock;
  }

  abstract getBlock(): BlockType;

  setEditorDispatch(
    editorDispatch: (
      event:
        | PublicEditorEvent<AvailableBlock | BlockType>
        | CoordinatorEvent<AvailableBlock | BlockType>,
    ) => boolean,
  ) {
    this.editorDispatch = editorDispatch;
  }

  abstract dispatch(
    event: BlockEvent<AvailableBlock, BlockType>,
    selection: TextSelection,
  ): boolean;

  abstract isTextBlock(): boolean;

  abstract toggleHighlight(
    selection: ContentSelection,
  ): ContentPatch<AvailableBlock[]> | void;

  abstract getSelectedContent(selection: ContentSelection): TextNode[] | void;

  abstract replaceSelectedContentWithPlaintext(
    selection: TextSelection,
    text: string,
  ): boolean;

  abstract replaceSelectedContent(
    selection: ContentSelection,
    data: EditorData<AvailableBlock>,
  ): ContentPatch<(AvailableBlock | BlockType)[]> | void;

  abstract pressShiftArrowUp(selection: ContentSelection): boolean;

  abstract pressShiftArrowDown(selection: ContentSelection): boolean;

  abstract pressArrowUp(isOnFirstLineOfBlock: () => boolean): boolean;

  abstract pressArrowDown(isOnLastLineOfBlock: () => boolean): boolean;

  abstract pressEnter(
    selection: ContentSelection,
  ): ContentPatch<AvailableBlock[]>;

  abstract pressShiftEnter(
    selection: ContentSelection,
  ): ContentPatch<AvailableBlock[]> | void;

  abstract getEndOfBlockSelection(): ContentSelection;

  abstract isEmptyDefaultBlock(): boolean;
}
