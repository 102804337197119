import ContentSelection from '../selection/contentSelection/ContentSelection.js';
import { ContentPatch } from '../ContentPatch.js';
import { EditorStateGeneric } from '../EditorStateGeneric.js';
import replaceSelectionWith from '../../pages/zeck/editor/BodyEditor/replaceSelectionWith.js';

const pressShiftEnter =
  <BlockType>(
    generateBlockEditor: (b: BlockType) => {
      pressShiftEnter: (
        selection: ContentSelection,
      ) => ContentPatch<BlockType[]> | void;
    },
  ) =>
  (initialState: EditorStateGeneric<BlockType>) =>
    replaceSelectionWith(initialState, {
      textSelection: (selectedBlock, selection) => {
        const targetBlockEditor = generateBlockEditor(selectedBlock);

        return targetBlockEditor.pressShiftEnter(selection);
      },
      blockSelection: () => {
        return;
      },
    });

export default pressShiftEnter;
