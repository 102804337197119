import ContentSelection from '../../../../editor/selection/contentSelection/ContentSelection.js';

export type TitleStateSelected = {
  content: string;
  selection: ContentSelection;
};

type TitleState = {
  content: string;
  selection: ContentSelection | null;
};

export type TitleContent = string;

export type TitleSelection = ContentSelection;

const TitleEditor = {
  edit: (
    _initialState: TitleStateSelected,
    editedState: TitleStateSelected,
  ) => ({
    content: editedState.content,
    selection: editedState.selection,
  }),
  select: (initialState: TitleState, newSelection: TitleSelection) => ({
    content: initialState.content,
    selection: newSelection,
  }),
};

export default TitleEditor;
