import { getTextFromNodes } from 'editor-content/TextNode.js';
import splitTextNodesFromContentSelection from '../textNode/splitTextNodesFromContentSelection.js';
import { TextBlock, updateTextBlock } from 'editor-content/Block.js';
import ContentSelection, {
  contentSelection,
  getStartOfSelection,
} from '../../selection/contentSelection/ContentSelection.js';
import EditorData from '../../../pages/zeck/editor/EditorData.js';
import { ContentPatch } from '../../ContentPatch.js';
import { textSelection } from '../../selection/TextSelection.js';
import { splitTextBlockWithBlocks } from '../../../pages/zeck/editor/BodyEditor/pasteBlocks.js';
import { HydratedBlock } from '../../../types/HydratedBlock.js';

const textBlockReplaceSelectedContentStrategy = <
  BlockType extends TextBlock,
  AvailableBlock extends HydratedBlock,
>(
  currentBlock: BlockType,
  selection: ContentSelection,
  data: EditorData<AvailableBlock | BlockType>,
): ContentPatch<(AvailableBlock | BlockType)[]> | void => {
  switch (data.type) {
    case 'text': {
      const newNodes = data.content;

      const [beforeNodes, , afterNodes] = splitTextNodesFromContentSelection(
        currentBlock.content,
        selection,
      );

      return {
        contentSubset: [
          updateTextBlock(currentBlock, [
            ...beforeNodes,
            ...newNodes,
            ...afterNodes,
          ]),
        ],
        selection: textSelection(
          0,
          contentSelection(
            getStartOfSelection(selection) + getTextFromNodes(newNodes).length,
          ),
        ),
      };
    }
    case 'block': {
      return splitTextBlockWithBlocks(currentBlock, selection, data.content);
    }
  }
};

export default textBlockReplaceSelectedContentStrategy;
