import { textSelection } from '../../../../editor/selection/TextSelection.js';
import splitTextNodesFromContentSelection from '../../../../editor/blocks/textNode/splitTextNodesFromContentSelection.js';
import {
  Block,
  getTextFromBlock,
  isTextBlock,
  newTextBlockOfType,
  TextBlock,
  updateTextBlock,
} from 'editor-content/Block.js';
import { getTextFromNodes } from 'editor-content/TextNode.js';
import { BodyStateSelected } from './BodyEditor.js';
import ContentSelection, {
  contentSelection,
} from '../../../../editor/selection/contentSelection/ContentSelection.js';
import replaceSelectionWith from './replaceSelectionWith.js';
import getTextBlockLength from '../../../../editor/blocks/textBlocksStrategies/getTextBlockLength.js';
import { ContentPatch } from '../../../../editor/ContentPatch.js';
import { HydratedBlock } from '../../../../types/HydratedBlock.js';

// editor has list of pasted blocks
// editor finds selection at end of pasted blocks
//   - asking colleagues "what is the selection at the end of you"
// editor calls selected block colleague with pasted blocks and new selection
// selected block colleague returns new content patch?

export const splitTextBlockWithBlocks = <
  ThisBlock extends TextBlock,
  AvailableBlocks extends Block,
>(
  block: ThisBlock,
  selection: ContentSelection,
  newBlocks: (AvailableBlocks | ThisBlock)[],
): ContentPatch<(AvailableBlocks | ThisBlock)[]> | void => {
  // this should probably be block colleague
  const lastPastedBlock = newBlocks[newBlocks.length - 1];
  if (!lastPastedBlock) return;
  const contentSelectionInLastBlock = contentSelection(
    isTextBlock(lastPastedBlock) ? getTextFromBlock(lastPastedBlock).length : 0,
  );

  const selectionRelativeToNewBlocks = {
    index: newBlocks.length - 1,
    offset: contentSelectionInLastBlock,
  };

  if (getTextBlockLength(block) === 0) {
    return {
      contentSubset: newBlocks,
      selection: selectionRelativeToNewBlocks,
    };
  }

  const [beforeContent, , afterContent] = splitTextNodesFromContentSelection(
    block.content,
    selection,
  );

  const afterContentIsEmpty = getTextFromNodes(afterContent).length === 0;
  const beforeContentIsEmpty = getTextFromNodes(beforeContent).length === 0;

  // at start of block
  if (beforeContentIsEmpty) {
    return {
      contentSubset: [...newBlocks, updateTextBlock(block, afterContent)],
      selection: selectionRelativeToNewBlocks,
    };
  }

  // at end of block
  if (afterContentIsEmpty) {
    return {
      contentSubset: [updateTextBlock(block, beforeContent), ...newBlocks],
      selection: {
        ...selectionRelativeToNewBlocks,
        index: selectionRelativeToNewBlocks.index + 1,
      },
    };
  }

  // in middle of block
  return {
    contentSubset: [
      updateTextBlock(block, beforeContent),
      ...newBlocks,
      newTextBlockOfType(block, afterContent),
    ],
    selection: {
      ...selectionRelativeToNewBlocks,
      index: selectionRelativeToNewBlocks.index + 1,
    },
  };
};

export default function pasteBlocks(
  initialState: BodyStateSelected,
  blocks: HydratedBlock[],
): BodyStateSelected | void {
  const lastPastedBlock = blocks[blocks.length - 1];
  if (!lastPastedBlock) return initialState;
  const contentSelectionInLastBlock = contentSelection(
    isTextBlock(lastPastedBlock) ? getTextFromBlock(lastPastedBlock).length : 0,
  );

  return replaceSelectionWith(initialState, {
    textSelection(selectedBlock, selection) {
      if (!isTextBlock(selectedBlock)) {
        return {
          contentSubset: blocks,
          selection: {
            index: blocks.length - 1,
            offset: contentSelectionInLastBlock,
          },
        };
      }

      return splitTextBlockWithBlocks(selectedBlock, selection, blocks);
    },
    blockSelection() {
      return {
        contentSubset: blocks,
        selection: textSelection(
          blocks.length - 1,
          contentSelectionInLastBlock,
        ),
      };
    },
  });
}
