import * as hubSpot from '../external/hubSpot.js';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import useBreakpoints from './services/useBreakpoints.js';
import { useEffect } from 'react';
import assertDefined from './junkDrawer/assertDefined.js';
import { Userpilot } from 'userpilot';
import { ErrorWithContext, logError } from './logging/logger.ts';
import { getCompanyIdFromPathname } from './userAndCompany/getCompanyIdFromPathname.ts';
import { CompanyLite, User } from './types.ts';

const noHubSpotRoutes = ['/company/:companyId/:slug'];
const noHubSpotOnMobileRoutes = [
  '/company/:companyId/s/:slug',
  '/company/:companyId/:slug',
  '/company/:id/s/:slug',
];

function showOrHideHubSpot(isMobile: boolean, currentRoute: string) {
  if (
    noHubSpotRoutes.includes(currentRoute) ||
    (isMobile && noHubSpotOnMobileRoutes.includes(currentRoute))
  ) {
    hubSpot.hide();
  } else {
    hubSpot.show();
  }
}

type RoutesProps = {
  routes: Parameters<typeof createBrowserRouter>[0];
  user: User | null;
  company?: CompanyLite;
};

class CompanyMismatchError extends ErrorWithContext {
  constructor(companyId: string, routeCompanyId: string | null) {
    super(
      `Active company mismatch.`,
      {
        companyId,
        routeCompanyId,
        url: window.location.href,
      },
      'CompanyMismatchError',
    );
  }
}

export function Routes({ routes, user, company }: RoutesProps) {
  const router = createBrowserRouter(routes);
  const { isMobile } = useBreakpoints();

  useEffect(() => {
    const checkPathnameCompanyMismatch = (companyId: string | undefined) => {
      const companyIdInURL = getCompanyIdFromPathname(window.location.pathname);

      if (companyIdInURL && companyId && companyIdInURL !== companyId) {
        logError(new CompanyMismatchError(companyId, companyIdInURL));
        window.location.reload();
      }
    };

    showOrHideHubSpot(
      isMobile,
      assertDefined(assertDefined(router.state.matches[0]).route.path),
    );

    let lastUrl: string | null = null;

    router.subscribe((state) => {
      const currentUrl = assertDefined(
        assertDefined(state.matches[0]).route.path,
      );

      showOrHideHubSpot(isMobile, currentUrl);

      if (currentUrl !== lastUrl) {
        Userpilot.reload();
        lastUrl = currentUrl;
      }

      checkPathnameCompanyMismatch(company?.id);
    });

    checkPathnameCompanyMismatch(company?.id);

    return () => {
      router.dispose();
    };
  });

  useEffect(() => {
    if (user) {
      Userpilot.identify(user.id, {
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
        ...(company && {
          company: {
            id: company.id,
            name: company.name,
          },
        }),
      });
    } else {
      Userpilot.anonymous();
    }
  }, [user, company]);

  return <RouterProvider router={router} />;
}
