import { Block } from 'editor-content/Block.js';
import Linkable from 'editor-content/html/Linkable.js';
import { v4 } from 'uuid';
import cleanupTextNodesForPaste from './cleanupTextNodesForPaste.js';
import { copyVoteBlock } from 'editor-content/VoteBlock.js';
import { copyAgendaBlock } from 'editor-content/AgendaBlock.js';
import assertUnreachable from '../../../junkDrawer/assertUnreachable.js';

const cleanupBlockForPaste = (block: Block, linkables: Linkable[]) => {
  switch (block.type) {
    case 'paragraph':
    case 'label':
    case 'bulleted-list-item':
    case 'numbered-list-item':
    case 'heading': {
      return {
        ...block,
        id: v4(),
        content: cleanupTextNodesForPaste(block.content, linkables),
      };
    }
    case 'vote':
      return copyVoteBlock(block);
    case 'agenda':
      return copyAgendaBlock(block);
    case 'image':
    case 'file':
    case 'video':
    case 'divider':
    case 'table':
    case 'carta-cap-table':
      return {
        ...block,
        id: v4(),
      };
  }

  return assertUnreachable(block);
};

export function cleanupBlocksForPaste(blocks: Block[], linkables: Linkable[]) {
  return blocks.map((block) => cleanupBlockForPaste(block, linkables));
}
