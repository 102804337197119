import replaceSelectionWith from '../../pages/zeck/editor/BodyEditor/replaceSelectionWith.js';
import { textSelection } from '../selection/TextSelection.js';
import ContentSelection, {
  contentSelection,
} from '../selection/contentSelection/ContentSelection.js';
import { EditorStateGeneric } from '../EditorStateGeneric.js';
import { TextNode } from 'editor-content/TextNode.js';
import { ContentPatch } from '../ContentPatch.js';

const pressEnter =
  <BlockType>(
    generateBlockEditor: (b: BlockType) => {
      pressEnter: (selection: ContentSelection) => ContentPatch<BlockType[]>;
    },
    createDefaultBlock: (content: TextNode[]) => BlockType,
  ) =>
  (initialState: EditorStateGeneric<BlockType>) =>
    replaceSelectionWith(initialState, {
      textSelection: (selectedBlock, selection) => {
        const targetBlockEditor = generateBlockEditor(selectedBlock);

        return targetBlockEditor.pressEnter(selection);
      },
      blockSelection: () => {
        return {
          contentSubset: [createDefaultBlock([])],
          selection: textSelection(0, contentSelection(0)),
        };
      },
    });

export default pressEnter;
