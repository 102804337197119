const errorMessagesWeDontCareAbout = [
  /__gCrWeb\./, // these seem to be due to a bug in iPad Chrome / Edge, ignoring to not show toast
  /Extension context/, // these seem to be a GoGuardian Extension error
  /Cannot redefine property: googletag/, // internet suggests this might be from Stands Adblocker
  /^Script error\.$/, // these seem to be due an error in a third party script we don't control or get extra info on. We suspect it is HelpScout
];

const errorStacksWeDontCareAbout = [
  'webkit-masked-url://', // safari extension
  'chrome-extension://', // chrome extensions
  'https://js.userpilot.io', // userpilot
];

const shouldIgnoreError = (
  e:
    | {
        message: string;
        stack?: string;
        error?: {
          stack?: string;
        };
      }
    | undefined,
) => {
  return (
    // This is designed to filter out extension errors we've been seeing where event.reason appears to be undefined
    // This potentially runs the risk of filtering out real errors, but we think the only way to have reason = undefined is to do
    // - Reject with no argument: new Promise((_, reject) => reject());
    // - Deliberately throw undefined in an async function: async () => { throw undefined; }
    // Both of which seem preposterous
    !e ||
    // filtering on message here to not show toast for some errors we can't control where the toast is more disruptive than the error
    errorMessagesWeDontCareAbout.some((errorRegexp) =>
      errorRegexp.test(e.message),
    ) ||
    // filtering on stacks here to errors that are coming from extension code
    errorStacksWeDontCareAbout.some(
      (errorStack) =>
        e.stack?.includes(errorStack) || e?.error?.stack?.includes(errorStack),
    )
  );
};

export default shouldIgnoreError;
