import { useState } from 'react';
import cx from 'classnames';
import styles from './PublishedCommentForm.module.scss';
import { CommentContent } from 'editor-content/CommentContent.js';
import { AvailableTag } from '../../../../../types/AvailableTag.js';
import CommentEditor from '../../../../../commentEditor/CommentEditor.js';
import InputCheckbox from '../../../../../design-system/atoms/InputCheckbox.js';
import OutlineButton from '../../../../../design-system/atoms/OutlineButton.js';
import Button from '../../../../../design-system/atoms/Button.js';
import CommentEditable from '../../../../../commentEditor/CommentEditable.js';
import PublishedCommentInputLayout from './PublishedCommentInputLayout.js';

/**
 * Comment, DirectMessage or object with content
 */
type CommentFormValue = {
  content: CommentContent;
  isDirectMessage: boolean;
};

type CommentFormProps = {
  availableTags: AvailableTag[];
  placeholder?: string;
  onCancel: () => void;
  onSubmit: (value: CommentFormValue) => Promise<void>;
  className?: string;
  testId: string;
  initialValue: CommentFormValue;
  canToggleDirectMessage: boolean;
  renderHelp?: (value: CommentFormValue) => React.ReactNode;
  submitText?: string;
};

function PublishedCommentForm({
  initialValue,
  availableTags,
  placeholder = '',
  onCancel,
  onSubmit,
  className,
  testId,
  canToggleDirectMessage,
  renderHelp,
  submitText = 'Submit',
}: CommentFormProps) {
  const [formState, setFormState] = useState<CommentFormValue>(initialValue);
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = async () => {
    setIsLoading(true);
    await onSubmit(formState);
    setIsLoading(false);
  };

  const taggedUserIds = CommentEditor.getTaggedUserIds(formState.content);

  const canToggleDMs =
    canToggleDirectMessage &&
    (formState.isDirectMessage || taggedUserIds.length > 0);

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        handleSubmit();
      }}
      className={cx(styles.comments__newComment, className)}
      data-testid={testId}
    >
      <PublishedCommentInputLayout
        footerSlot={
          canToggleDMs && (
            <div className={styles.commentForm__dm_info}>
              <InputCheckbox
                backgroundColor="white"
                label="Make a direct message visible only to people you ‘@’ tag."
                value={formState.isDirectMessage}
                onChange={(isDirectMessage) =>
                  setFormState((formState) => ({
                    ...formState,
                    isDirectMessage,
                  }))
                }
              />
            </div>
          )
        }
      >
        <CommentEditable
          aria-label={`${testId}-input`}
          onPressEnter={handleSubmit}
          autofocus
          scrollContainerRef={{ current: null }}
          availableTags={availableTags}
          tagTypeDisplayName="Viewers"
          placeholder={placeholder}
          commentContent={formState.content}
          onChange={(newContent) =>
            setFormState((formState) => ({ ...formState, content: newContent }))
          }
          className={styles.comments__comment__input}
        />
      </PublishedCommentInputLayout>

      {renderHelp && renderHelp(formState)}

      <div className={styles.comments__comment__newComment__buttons}>
        <OutlineButton
          size="medium"
          color="secondary"
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            onCancel();
          }}
          data-testid={`cancel-${testId}`}
        >
          Cancel
        </OutlineButton>
        <Button
          data-testid={`submit-${testId}`}
          size="medium"
          color="primary"
          type="submit"
          disabled={
            !CommentEditor.canSubmit({
              content: formState.content,
              selection: null,
            }) || isLoading
          }
        >
          {submitText}
        </Button>
      </div>
    </form>
  );
}

export default PublishedCommentForm;
