import TurnIntoMenu, { TurnIntoable } from '../molecules/TurnIntoMenu.tsx';
import { useState } from 'react';
import Toolbar, { ToolbarDropdownButton, ToolbarGroup } from './Toolbar.tsx';
import styles from './TextFormattingMenu.module.scss';
import IconButton from '../atoms/IconButton.tsx';

type MultiblockSelectionMenuProps = {
  turnIntoables: TurnIntoable[];
  onIndent?: () => void;
  onDedent?: () => void;
};

export default function BlockFormattingMenu({
  turnIntoables,
  onIndent,
  onDedent,
}: MultiblockSelectionMenuProps) {
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);

  return (
    <>
      <Toolbar>
        <ToolbarGroup>
          <ToolbarDropdownButton
            onClick={() => {
              setDropdownIsOpen(true);
            }}
          >
            Turn Into
          </ToolbarDropdownButton>
        </ToolbarGroup>

        {(onIndent || onDedent) && (
          <ToolbarGroup>
            {onDedent && (
              <IconButton
                name="indentDecrease"
                type="dark"
                onClick={() => onDedent()}
                aria-label="decrease text indent"
              />
            )}
            {onIndent && (
              <IconButton
                name="indentIncrease"
                type="dark"
                onClick={() => onIndent()}
                aria-label="increase text indent"
              />
            )}
          </ToolbarGroup>
        )}
      </Toolbar>

      {dropdownIsOpen && (
        <TurnIntoMenu
          className={styles.turnIntoMenu}
          turnIntoables={turnIntoables}
          onClickMenuItem={() => {
            setDropdownIsOpen(false);
          }}
        />
      )}
    </>
  );
}
