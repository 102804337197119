import { useEffect, useState } from 'react';
import { Result } from './useFetch.js';
import {
  ErrorResult,
  LoadingResult,
  SuccessResult,
} from '../../result/Result.js';

const useFetchInvalidate = <T>(fn: () => Promise<T>, deps: Array<unknown>) => {
  const [result, setResult] = useState<Result<T>>(LoadingResult());

  useEffect(() => {
    (async () => {
      setResult(LoadingResult());
      try {
        setResult(SuccessResult(await fn()));
      } catch (e) {
        setResult(ErrorResult(undefined));
      }
    })();
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps

  const invalidate = async (
    action: () => Promise<void>,
    optimisticData?: T,
  ) => {
    if (optimisticData) {
      setResult(SuccessResult(optimisticData));
    }

    await action();
    try {
      setResult(SuccessResult(await fn()));
    } catch (e) {
      setResult(ErrorResult(undefined));
    }
  };

  return {
    result,
    invalidate,
  };
};

export default useFetchInvalidate;
