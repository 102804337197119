import splitContentByBlockSelection from './selection/splitContentByBlockSelection.js';
import { TextSelection, textSelection } from './selection/TextSelection.js';
import { contentSelection } from './selection/contentSelection/ContentSelection.js';
import { BlockSelection } from './selection/BlockSelection.js';
import { BlockContent } from 'editor-content/Block.js';
import { Text } from 'editor-content/TextNode.js';

const blockSelectionReplaceSelectedContentWithPlaintext = <BlockType>(
  editorState: {
    content: BlockType[];
    selection: BlockSelection;
  },
  createDefaultBlock: (content: BlockContent) => BlockType,
  plaintext: string,
): { content: BlockType[]; selection: TextSelection } | void => {
  const lines = plaintext.split('\n');
  const lastLine = lines[lines.length - 1];

  if (!lastLine) return;

  const newBlocks = lines.map((s) => createDefaultBlock([Text(s)]));

  const [beforeBlocks, , afterBlocks] = splitContentByBlockSelection(
    editorState.content,
    editorState.selection,
  );

  return {
    content: [...beforeBlocks, ...newBlocks, ...afterBlocks],
    selection: textSelection(
      beforeBlocks.length + lines.length - 1,
      contentSelection(lastLine.length),
    ),
  };
};

export default blockSelectionReplaceSelectedContentWithPlaintext;
