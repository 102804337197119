import ContentSelection from '../../../../editor/selection/contentSelection/ContentSelection.js';
import splitTextNodesFromContentSelection from '../../../../editor/blocks/textNode/splitTextNodesFromContentSelection.js';
import assertUnreachable from '../../../../junkDrawer/assertUnreachable.js';
import { Highlight, TextNode } from 'editor-content/TextNode.js';

export default function addHighlightToTextNodes(
  nodes: TextNode[],
  selection: ContentSelection,
  id: string,
): TextNode[] {
  const [beforeSelectionNodes, inSelectionNodes, afterSelectionNodes] =
    splitTextNodesFromContentSelection(nodes, selection);

  return [
    ...beforeSelectionNodes,
    ...inSelectionNodes.map((textNode) => {
      switch (textNode.type) {
        case 'highlight':
          return Highlight([...textNode.ids, id], textNode.content);
        case 'text':
        case 'external-link':
        case 'section-link':
        case 'meeting-minutes-link':
          return Highlight([id], [textNode]);
      }

      return assertUnreachable(textNode);
    }),
    ...afterSelectionNodes,
  ];
}
