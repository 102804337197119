import cx from 'classnames';
import { ReactNode } from 'react';
import { useMediaQuery } from 'react-responsive';
import { AnalyticsSummary } from '../../../../api/endpoints/createAnalyticsApi.js';
import Pill from '../../../../design-system/atoms/Pill.js';
import PersonView from '../../../../design-system/molecules/PersonView.js';
import Tooltip from '../../../../design-system/molecules/Tooltip.js';
import { useFeatureFlags } from '../../../../feature-flags/FeatureFlagsContext.js';
import formatTimeAgo from '../../../../services/formatTimeAgo.js';
import { isPendingUser, UserAndPermission, Zeck } from '../../../../types.js';
import AnalyticsEmptyModal from './AnalyticsEmptyModal.js';
import ViewerEngagementSummary from './components/ViewerEngagementSummary.js';
import { Table, TableCell, TableHeaderCell } from './Table.js';
import getHoursSpent from './util/getHoursSpent.js';
import styles from './ViewerEngagementModal.module.scss';

type View = { userId: string; lastViewedAt: number };
type ZeckAnalyticsType = Pick<Zeck, 'id' | 'firstPublishedAt'>;

const ZeckViewCell: React.FC<{
  zeck: ZeckAnalyticsType;
  dataTestId?: string;
  alignRight?: boolean;
  children: ReactNode;
  goLiveDate: Date;
  contentType: 'Viewer' | 'Time Spent';
}> = ({ zeck, dataTestId, children, contentType, alignRight, goLiveDate }) => {
  const { showAllAnalytics } = useFeatureFlags();
  if (!zeck.firstPublishedAt) {
    console.error('No publishedAt date for', zeck);
    return null;
  }

  const dataAvailable =
    showAllAnalytics || new Date(zeck.firstPublishedAt) > goLiveDate;

  if (!dataAvailable) {
    return (
      <TableCell
        data-testid={dataTestId}
        className={cx(!dataAvailable && styles.analyticsModal__noData)}
      >
        No data
        <div className={styles.infoToolTip}>
          <Tooltip>
            {' '}
            Zecks published before {goLiveDate.toLocaleDateString()} will have
            no {contentType} data.
          </Tooltip>
        </div>
      </TableCell>
    );
  }

  return (
    <TableCell alignRight={alignRight} data-testid={dataTestId}>
      {children}
    </TableCell>
  );
};

const AnalyticsRow = ({
  user,
  view,
  zeck,
  isMobile,
  secondsSpent,
  zeckScrollGoLiveDate,
  zeckViewGoLiveDate,
}: {
  user: UserAndPermission;
  view: View | undefined;
  zeck: ZeckAnalyticsType;
  isMobile: boolean;
  secondsSpent: number;
  zeckViewGoLiveDate: Date;
  zeckScrollGoLiveDate: Date;
}) => {
  return (
    <>
      <TableCell>
        <PersonView user={user} />
      </TableCell>

      {!isMobile && (
        <ZeckViewCell
          zeck={zeck}
          dataTestId={`analytics-viewed-at-${user.id}`}
          goLiveDate={zeckViewGoLiveDate}
          contentType="Viewer"
        >
          {view ? formatTimeAgo(view?.lastViewedAt, true) : ''}
        </ZeckViewCell>
      )}

      <ZeckViewCell
        zeck={zeck}
        goLiveDate={zeckViewGoLiveDate}
        contentType="Viewer"
        alignRight={isMobile}
      >
        {view && <Pill label="Viewed" size="bubble" color="primary" />}
        {!view && <Pill label="Not Viewed" size="bubble" color="gray" />}
      </ZeckViewCell>

      {!isMobile && (
        <ZeckViewCell
          dataTestId={`analytics-view-time-${user.id}`}
          zeck={zeck}
          goLiveDate={zeckScrollGoLiveDate}
          contentType="Time Spent"
        >
          {getHoursSpent(secondsSpent)}
        </ZeckViewCell>
      )}
    </>
  );
};

const sortUsers = (a: UserAndPermission, b: UserAndPermission) => {
  if (isPendingUser(a) === isPendingUser(b)) {
    const sortKeyA = isPendingUser(a) ? a.email : a.firstName;
    const sortKeyB = isPendingUser(b) ? b.email : b.firstName;
    return sortKeyA.localeCompare(sortKeyB);
  }

  return isPendingUser(a) ? 1 : -1;
};

type ViewerEngagementModalPropsType = {
  zeck: Pick<Zeck, 'id' | 'firstPublishedAt'>;
  analyticsSummary: AnalyticsSummary;
  users: UserAndPermission[];
  zeckScrollGoLiveDate: Date;
  zeckViewGoLiveDate: Date;
  modal?: boolean;
};

const ViewerEngagementModal: React.FC<ViewerEngagementModalPropsType> = ({
  zeck,
  analyticsSummary,
  zeckScrollGoLiveDate,
  zeckViewGoLiveDate,
  users,
  modal,
}) => {
  const { showAllAnalytics } = useFeatureFlags();
  const isMobile = useMediaQuery({ maxWidth: 736 });

  const hasEngagementData =
    showAllAnalytics ||
    zeckScrollGoLiveDate < new Date(zeck.firstPublishedAt ?? 0);

  const shouldRenderUser = (user: UserAndPermission) => {
    return user.role === 'viewer';
  };

  const filteredUsers = users.filter(shouldRenderUser).sort(sortUsers);

  if (!filteredUsers.length) {
    return (
      <AnalyticsEmptyModal
        title="This Zeck doesn’t have Viewers yet."
        body="Add People as Viewers in the Manage Viewers tab."
      />
    );
  }

  const maybeFullHeightClass = modal
    ? !hasEngagementData
      ? styles.viewerEngagementTable__withoutSummary
      : styles.viewerEngagementTable__modal
    : null;

  return (
    <div className={styles.viewerEngagementTable__wrapper}>
      {hasEngagementData && (
        <ViewerEngagementSummary summary={analyticsSummary.engagementSummary} />
      )}

      <Table
        data-testid="analytics-modal"
        className={cx([styles.viewerEngagementTable, maybeFullHeightClass])}
      >
        <TableHeaderCell>Viewers</TableHeaderCell>
        {!isMobile && <TableHeaderCell>Last Viewed</TableHeaderCell>}
        <TableHeaderCell alignRight={isMobile}>Status</TableHeaderCell>
        {!isMobile && <TableHeaderCell>Time Spent</TableHeaderCell>}

        {filteredUsers.map((user) => {
          return (
            <AnalyticsRow
              key={`analytics-row-${user.id}`}
              user={user}
              view={analyticsSummary.getUserView(user.id)}
              zeck={zeck}
              isMobile={isMobile}
              secondsSpent={analyticsSummary.userTotals[user.id] ?? 0}
              zeckScrollGoLiveDate={zeckScrollGoLiveDate}
              zeckViewGoLiveDate={zeckViewGoLiveDate}
            />
          );
        })}
      </Table>
    </div>
  );
};

export default ViewerEngagementModal;
