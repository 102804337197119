import ZeckEditorSelection from './ZeckEditorSelection.ts';
import { ElementAndData } from '../../../junkDrawer/useElementAndDataArray.ts';
import { Company, User } from '../../../types.ts';
import { isTextSelection } from '../../../editor/selection/TextSelection.ts';
import ImageFormattingExperience from './ImageFormattingExperience.tsx';
import VoteFormattingExperience from './VoteFormattingExperience.tsx';
import FileFormattingExperience from './FileFormattingExperience.tsx';
import { Block, FileBlock, ImageBlock } from 'editor-content/Block.js';
import SelectionCommentWithActions from './comments/SelectionCommentWithActions.ts';
import { CommentContentNode } from 'editor-content/CommentContent.js';
import TableFormattingExperience from './TableFormattingExperience.tsx';
import { TableBlock } from 'editor-content/TableBlock.js';
import { IntegrationListItemData } from '../../../api/endpoints/createIntegrationApi.ts';

type BlockFormattingMenuProps = {
  blocksWithEl: ElementAndData<Block>[];
  selection: ZeckEditorSelection;
  company: Pick<Company, 'id'>;
  onSetImageWidth: (width: ImageBlock['width']) => void;
  onSetImageAlign: (align: ImageBlock['align']) => void;
  onReplaceImage: (imageId: string, width: number, height: number) => void;
  onDeleteImage: () => void;
  onAddSelectionComment: (
    blockId: string,
    commentText: CommentContentNode[],
  ) => Promise<void>;
  onReplaceFile: (file: Pick<FileBlock, 'guid' | 'filename'>) => void;
  onReplaceTable: (table: Pick<TableBlock, 'data'>) => void;
  onDeleteFile: () => void;
  user: User;
  selectionComments: SelectionCommentWithActions[];
  integrationData: IntegrationListItemData;
  zeckId: string;
  sectionId: string;
};

const BlockFormattingMenu: React.VFC<BlockFormattingMenuProps> = ({
  blocksWithEl,
  selection,
  company,
  onSetImageWidth,
  onSetImageAlign,
  onReplaceImage,
  onDeleteImage,
  onAddSelectionComment,
  onReplaceFile,
  onDeleteFile,
  onReplaceTable,
  user,
  selectionComments,
  zeckId,
  sectionId,
  integrationData,
}) => {
  if (
    !(selection && selection.target === 'body' && isTextSelection(selection))
  ) {
    return null;
  }

  const blockWithEl = blocksWithEl[selection.index];
  if (!blockWithEl) return null;

  const block = blockWithEl.data;

  switch (block.type) {
    case 'image':
      return (
        <ImageFormattingExperience
          {...{
            key: block.id,
            block,
            getEl: blockWithEl.getEl,
            company,
            onSetImageWidth,
            onSetImageAlign,
            onReplaceImage,
            onDeleteImage,
            onAddSelectionComment,
            user,
            selectionComments,
            companyId: company.id,
            zeckId,
            sectionId,
          }}
        />
      );
    case 'vote':
      return (
        <VoteFormattingExperience
          {...{
            key: block.id,
            block,
            getEl: blockWithEl.getEl,
            onAddSelectionComment,
            user,
            selectionComments,
            companyId: company.id,
            zeckId,
            sectionId,
          }}
        />
      );
    case 'file':
      return (
        <FileFormattingExperience
          {...{
            key: block.id,
            block,
            getEl: blockWithEl.getEl,
            company,
            onDeleteFile,
            onReplaceFile,
            onAddSelectionComment,
            user,
            selectionComments,
            companyId: company.id,
            zeckId,
            sectionId,
          }}
        />
      );
    case 'table':
      return (
        <TableFormattingExperience
          {...{
            key: block.id,
            block,
            getEl: blockWithEl.getEl,
            onReplaceTable,
            user,
            companyId: company.id,
            integrationData,
            zeckId,
            sectionId,
          }}
        />
      );
    case 'paragraph':
      break;
    case 'label':
      break;
    case 'bulleted-list-item':
      break;
    case 'numbered-list-item':
      break;
    case 'heading':
      break;
  }

  return null;
};

export default BlockFormattingMenu;
