import {
  createBulletedListItemBlock,
  createParagraphBlock,
} from 'editor-content/Block.js';

import React, { useCallback } from 'react';
import { CoordinatorEvent, PublicEditorEvent } from '../../EditorEvents.ts';
import ResolutionTemplateModal from '../../../pages/meetingMinutes/edit/components/ResolutionTemplateModal.tsx';
import { VoteType } from 'editor-content/VoteBlock.js';
import { generateEmptyVoteContent } from 'editor-content/lib/generateVoteContent.js';
import { MeetingMinutesBlock } from 'editor-content/MeetingMinutes/MeetingMinutesBlock.ts';
import AddBlockExperience from '../AddBlockExperience.js';
import { ElementAndData } from '../../../junkDrawer/useElementAndDataArray.js';

type AddBlockComponentProps = {
  dispatch: (
    event:
      | PublicEditorEvent<MeetingMinutesBlock>
      | CoordinatorEvent<MeetingMinutesBlock>,
  ) => void;
  blocksWithEl: ElementAndData<MeetingMinutesBlock>[];
  leftGutterRef: React.RefObject<HTMLElement>;
};

export default function MeetingMinutesAddBlock({
  dispatch,
  blocksWithEl,
  leftGutterRef,
}: AddBlockComponentProps) {
  return (
    <AddBlockExperience<MeetingMinutesBlock>
      blocksWithEl={blocksWithEl}
      leftGutterRef={leftGutterRef}
      onAddNewBlock={(targetIndex) => {
        const newBlock = createParagraphBlock([]);
        dispatch({
          type: 'addNewBlock',
          data: {
            targetIndex,
            newBlock,
          },
        });
        return newBlock.id;
      }}
      onReplaceNewBlock={(newContent, targetBlockId) => {
        dispatch({
          type: 'replaceNewBlock',
          data: {
            targetBlockId,
            newContent,
          },
        });
      }}
      onConfigureComplexBlock={useCallback(() => {
        dispatch({
          type: 'selection',
          data: null,
        });
      }, [dispatch])}
      addBlockOptions={[
        {
          type: 'block',
          label: 'Bulleted List',
          blockType: 'bulleted-list-item',
          description: 'A simple list of items',
          iconName: 'bulletedList',
          createNewBlock: () => [createBulletedListItemBlock([], 0)],
        },
        {
          type: 'complex-block',
          label: 'Resolution Template',
          blockType: 'paragraph',
          description: 'Insert template language for votes',
          iconName: 'vote',
          renderAdding: ({ onAdd, onCancel }) => (
            <ResolutionTemplateModal
              isOpen
              onClose={onCancel}
              onInsert={function (voteType: VoteType): void {
                onAdd(generateEmptyVoteContent(voteType));
              }}
            />
          ),
        },
      ]}
    />
  );
}
