import {
  callHandlers,
  handleKey,
  preventDefault,
  stopPropagation,
} from '../../../../editor/domFacing/events/isKeyMatch.js';
import { KeyboardEvent, KeyboardEventHandler } from 'react';

const handleNonTextBlockKeyEvents = <E extends HTMLElement>({
  onEnter,
  onNavLeft,
  onNavRight,
}: {
  onEnter: KeyboardEventHandler<E>;
  onNavLeft: KeyboardEventHandler<E>;
  onNavRight: KeyboardEventHandler<E>;
}) =>
  callHandlers<KeyboardEvent<E>>([
    handleKey({ key: 'Enter' }, preventDefault(stopPropagation(onEnter))),
    handleKey({ key: 'ArrowUp' }, onNavLeft),
    handleKey({ key: 'ArrowDown' }, onNavRight),
    handleKey({ key: 'ArrowLeft' }, onNavLeft),
    handleKey({ key: 'ArrowRight' }, onNavRight),
  ]);

export default handleNonTextBlockKeyEvents;
