import { EditorStateGeneric } from '../EditorStateGeneric.js';
import ContentSelection from '../selection/contentSelection/ContentSelection.js';
import { isTextSelection } from '../selection/TextSelection.js';
import getSelectedBlock from '../../pages/zeck/editor/BodyEditor/getSelectedBlock.js';
import { BlockSelection, blockSelection } from '../selection/BlockSelection.js';

export default function pressShiftArrowUp<BlockType>(
  generateBlockEditor: (b: BlockType) => {
    pressShiftArrowUp: (selection: ContentSelection) => boolean;
  },
  initialState: EditorStateGeneric<BlockType>,
): { content: BlockType[]; selection: BlockSelection } | void {
  const { selection, content } = initialState;

  if (!selection) return;
  if (isTextSelection(selection)) {
    const selectedBlock = getSelectedBlock(content, selection);

    if (!selectedBlock) return;

    const targetBlockEditor = generateBlockEditor(selectedBlock);

    if (targetBlockEditor.pressShiftArrowUp(selection.offset)) {
      return {
        content,
        selection: blockSelection(selection.index, selection.index),
      };
    }

    return;
  }

  if (selection.focusIndex === 0) {
    // do nothing
    return;
  }

  return {
    content,
    selection: {
      anchorIndex: selection.anchorIndex,
      focusIndex: selection.focusIndex - 1,
    },
  };
}
