import { RefObject } from 'react';
import ContentSelection, {
  contentSelectionIsEqual,
} from '../selection/contentSelection/ContentSelection.js';
import BaseAndExtent from '../selection/contentSelection/BaseAndExtent.js';
import useDocumentEventListener from '../../junkDrawer/useDocumentEventListener.js';
import { getContentSelectionFromContainer } from './getContentSelectionFromContainer.js';

const useOnBrowserSelectionChange = (
  ref: RefObject<HTMLElement>,
  onSelect: (selection: ContentSelection) => void,
  currentSelection: ContentSelection | null,
  fromBaseAndExtent: (
    baseAndExtent: BaseAndExtent,
    containerElement: HTMLElement,
  ) => ContentSelection,
) => {
  useDocumentEventListener(
    'selectionchange',
    () => {
      const containerElement = ref.current;

      if (!containerElement) return;

      const newSelection = getContentSelectionFromContainer(
        containerElement,
        fromBaseAndExtent,
      );

      if (!newSelection) return;

      // probably because typing sends selectionchange events and change events,
      // so useOnChangeWithSelection will also be trying to redundantly set selection
      if (!contentSelectionIsEqual(currentSelection, newSelection)) {
        onSelect({ ...newSelection });
      }
    },
    [onSelect, ref, currentSelection],
  );
};

export default useOnBrowserSelectionChange;
