import React, { MouseEventHandler } from 'react';
import { getRectFromEl } from '../../domHelpers/Rect.js';
import HoverNextToPoint from '../../domHelpers/hoverNextTo/HoverNextToPoint.js';
import getAddBlockButtonLocation from './getAddBlockButtonLocation.js';
import AddBlockButton from '../../design-system/atoms/AddBlockButton.js';

const FloatingAddBlockButton: React.FC<{
  getBlockEl: () => HTMLElement | undefined;
  leftGutterRef: React.RefObject<HTMLElement>;
  onClick: MouseEventHandler;
}> = ({ getBlockEl, leftGutterRef, onClick }) => {
  return (
    <HoverNextToPoint
      containerStyles={{ zIndex: 'initial' }} // glob save us from z index
      viewportPolicy="none"
      getPoint={(popoverEl) => {
        const blockEl = getBlockEl();
        const leftGutterEl = leftGutterRef.current;
        if (!blockEl || !leftGutterEl) return null;

        const blockRect = getRectFromEl(blockEl);
        const leftGutterRect = getRectFromEl(leftGutterEl);

        const buttonSize = popoverEl.getBoundingClientRect();

        return getAddBlockButtonLocation(blockRect, leftGutterRect, buttonSize);
      }}
    >
      <AddBlockButton onClick={onClick} />
    </HoverNextToPoint>
  );
};

export default FloatingAddBlockButton;
