import cx from 'classnames';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import {
  AnalyticsSummary,
  UserEngagementTotal,
} from '../../../../api/endpoints/createAnalyticsApi.js';
import useApi from '../../../../api/useApi.ts';
import IconLink from '../../../../design-system/atoms/IconLink.js';
import Spinner from '../../../../design-system/atoms/Spinner.tsx';
import { isSuccessResult } from '../../../../result/Result.ts';
import { sectionPublishPath } from '../../../../services/Paths.js';
import useFetch from '../../../../services/useFetch/useFetch.js';
import { PublishedSection } from '../../../../types.ts';
import styles from './ContentEngagementModal.module.scss';
import { Table, TableCell, TableHeaderCell } from './Table.js';
import ContentEngagementSummary from './components/ContentEngagementSummary.tsx';
import countCommentsAndReplies from './util/countCommentsAndReplies.ts';
import getSectionTotals from './util/getSectionTotals.js';

const ConentEngagementPercent: React.FC<{
  widthMultiplier: number;
  children: React.ReactNode;
  isMobile: boolean;
}> = ({ widthMultiplier, isMobile, children }) => {
  if (isMobile) {
    return (
      <div className={styles.sectionTotal__percentLabel__mobile}>
        <div>{children}</div>
      </div>
    );
  }

  return (
    <div className={styles.sectionTotal__barWrap}>
      <div
        className={styles.sectionTotal__bar}
        data-width={widthMultiplier.toFixed(3)}
        style={{
          width: `calc(${widthMultiplier} * (100% - 58px))`,
        }}
        data-testid={`row-bar`}
      />
      <div
        className={styles.sectionTotal__percentLabel}
        style={{
          left: `${widthMultiplier}%`,
          marginLeft: widthMultiplier > 0 ? '8px' : 0,
        }}
      >
        {children}
      </div>
    </div>
  );
};

const getValidSectionTotals = (
  totals: UserEngagementTotal[],
  publishedSections: PublishedSection[],
) => {
  const zeckSectionIds = publishedSections.map((section) => section.sectionId);

  return totals.filter(
    (totalItem) => zeckSectionIds.indexOf(totalItem.sectionId) !== -1,
  );
};

const ContentEngagementModal: React.FC<{
  analyticsSummary: AnalyticsSummary;
  companyId: string;
  zeckId: string;
  publishedSections: PublishedSection[];
  modal?: boolean;
}> = ({ companyId, publishedSections, zeckId, analyticsSummary, modal }) => {
  const isMobile = useMediaQuery({ maxWidth: 736 });
  const api = useApi();

  const commentsResult = useFetch(() => api.getZeckComments(zeckId), [zeckId]);

  if (!isSuccessResult(commentsResult)) {
    return (
      <div data-testid="engagement-spinner" className={styles.spinnerWrapper}>
        <Spinner />
      </div>
    );
  }

  const commentCounts = countCommentsAndReplies(commentsResult.data);

  const totalCommentCount = Object.keys(commentCounts).reduce(
    (total, sectionId) => total + (commentCounts[sectionId] ?? 0),
    0,
  );

  const totalsForCurrentZeckSections = getValidSectionTotals(
    analyticsSummary.engagementTotals,
    publishedSections,
  );
  const totals = getSectionTotals(totalsForCurrentZeckSections);

  const sortedSectionsWithTotals = publishedSections
    .map((section) => {
      const total = totals.sectionTotals.find(
        (total) => total.sectionId === section.sectionId,
      ) ?? {
        sectionId: section.sectionId,
        totalTime: 0,
        percentOfTotal: '0.0%',
      };

      return {
        ...section,
        total,
      };
    })
    .sort((a, b) => b.total.totalTime - a.total.totalTime);

  const tableClass = modal
    ? cx(styles.contentEngagementTable, styles.contentEngagementTable__modal)
    : styles.contentEngagementTable;

  return (
    <div>
      <ContentEngagementSummary
        analyticsSummary={analyticsSummary}
        totalCommentCount={totalCommentCount}
      />

      {isMobile && (
        <div className={styles.contentEngagementTable__mobile_header}>
          <div>Section</div>
          <div>Where Viewers Spent Their Time</div>
        </div>
      )}
      <Table className={tableClass} data-testid="analytics-engagement-section">
        {!isMobile && (
          <>
            <TableHeaderCell>Section Title</TableHeaderCell>
            <TableHeaderCell>Where Viewers Spent Their Time</TableHeaderCell>
            <TableHeaderCell
              className={styles.sectionTotal__commentCountHeader}
            >
              Comments
            </TableHeaderCell>
          </>
        )}

        {sortedSectionsWithTotals.map((sectionWithTotal) => {
          const widthMultiplier =
            sectionWithTotal.total.totalTime /
            (sortedSectionsWithTotals[0]?.total.totalTime || 1);

          return (
            <React.Fragment key={sectionWithTotal.id}>
              <TableCell
                className={cx(styles.engagementModal__sectionTitleCell)}
                data-testid={`row-title`}
              >
                <div className={styles.engagementModal__sectionTitle}>
                  {sectionWithTotal.title}
                </div>
                <IconLink
                  name="goTo"
                  aria-label="go to site"
                  href={sectionPublishPath(sectionWithTotal, companyId)}
                  target="_blank"
                  className={styles.engagementModal__sectionLink}
                />
              </TableCell>
              <TableCell
                className={styles.sectionTotal__percentCell}
                data-testid={`row-bar-with-label-${sectionWithTotal.sectionId}`}
              >
                <ConentEngagementPercent
                  widthMultiplier={widthMultiplier}
                  isMobile={isMobile}
                >
                  {sectionWithTotal.total.percentOfTotal}
                </ConentEngagementPercent>
              </TableCell>
              {!isMobile && (
                <TableCell className={styles.sectionTotal__commentCountCell}>
                  {commentCounts[sectionWithTotal.sectionId] ?? 0}
                </TableCell>
              )}
            </React.Fragment>
          );
        })}
      </Table>
    </div>
  );
};

export default ContentEngagementModal;
