import ReactDropdown from 'react-dropdown';
import { Icon } from 'icons';
import styles from './Dropdown.module.scss';

export type DropdownProps<ValueType extends string> = {
  options: {
    value: ValueType;
    label: string;
  }[];
  value: ValueType | undefined;
  onChange: (val: ValueType) => void;
  placeholder: string;
  containerRef?: React.RefCallback<HTMLElement>;
};

function Dropdown<ValueType extends string>({
  options,
  value,
  onChange,
  placeholder,
  containerRef,
  ...props
}: DropdownProps<ValueType>) {
  return (
    <div ref={containerRef} {...props}>
      <ReactDropdown
        options={options}
        onChange={(option) => {
          onChange(option.value as ValueType);
        }}
        value={value}
        placeholder={placeholder}
        className={styles.dropdown__root}
        controlClassName={styles.dropdown__control}
        placeholderClassName={styles.dropdown__placeholder}
        menuClassName={styles.dropdown__menu}
        arrowClassName={styles.dropdown__arrow}
        arrowClosed={
          <Icon name="chevronDown" className={styles.dropdown__arrow} />
        }
        arrowOpen={
          <Icon name="chevronDown" className={styles.dropdown__arrow} />
        }
      />
    </div>
  );
}

export default Dropdown;
