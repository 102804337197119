import React from 'react';
import cx from 'classnames';
import styles from './PublishedCommentInputLayout.module.scss';

const PublishedCommentInputLayout: React.FC<{
  className?: string;
  children: React.ReactNode;
  footerSlot?: React.ReactNode;
}> = ({ children, footerSlot, className }) => {
  return (
    <div className={cx(styles.commentInputLayout, className)}>
      <div className={styles.commentInputLayout__main}>{children}</div>
      {footerSlot && (
        <div className={styles.commentInputLayout__footer}>{footerSlot}</div>
      )}
    </div>
  );
};

export default PublishedCommentInputLayout;
