import { EditorStateGeneric } from '../../../../editor/EditorStateGeneric.js';
import {
  EditorSelection,
  isBlockSelection,
  isTextSelection,
} from '../../../../editor/EditorSelection.js';
import splitContentByBlockSelection from '../../../../editor/selection/splitContentByBlockSelection.js';
import { createParagraphBlock, isTextBlock } from 'editor-content/Block.js';
import { getStartOfBlockSelection } from '../../../../editor/selection/BlockSelection.js';
import { textSelection } from '../../../../editor/selection/TextSelection.js';
import ContentSelection, {
  contentSelection,
} from '../../../../editor/selection/contentSelection/ContentSelection.js';
import { TextNode } from 'editor-content/TextNode.js';
import EditorData from '../EditorData.js';
import { HydratedBlock } from '../../../../types/HydratedBlock.js';
import getSelectedBlock from './getSelectedBlock.js';
import { applySelection, ContentPatch } from './replaceSelectionWith.js';
import replaceBlockAt from './replaceBlockAt.js';
import textBlockGetSelectedContentStrategy from '../../../../editor/blocks/textBlocksStrategies/textBlockGetSelectedContentStrategy.js';
import textBlockReplaceSelectedContentStrategy from '../../../../editor/blocks/textBlocksStrategies/textBlockReplaceSelectedContentStrategy.js';

const cut =
  <BlockType>(
    generateBlockEditor: (b: BlockType) => {
      cut: (
        selection: ContentSelection,
      ) =>
        | [
            newValue: ContentPatch<BlockType[]>,
            returnValue: EditorData<BlockType>,
          ]
        | void;
    },
    createDefaultBlock: (content: TextNode[]) => BlockType,
  ) =>
  (
    initialState: EditorStateGeneric<BlockType>,
  ):
    | [
        newEditorState: {
          content: BlockType[];
          selection: NonNullable<EditorSelection>;
        },
        returnValue: EditorData<BlockType>,
      ]
    | void => {
    const { content, selection } = initialState;

    // this is basically the same as `replaceSelectionWith` but with a return value
    if (isBlockSelection(selection)) {
      const [beforeBlocks, selectedBlocks, afterBlocks] =
        splitContentByBlockSelection(content, selection);

      return [
        {
          content: [...beforeBlocks, createDefaultBlock([]), ...afterBlocks],
          selection: textSelection(
            getStartOfBlockSelection(selection),
            contentSelection(0),
          ),
        },
        { type: 'block', content: selectedBlocks },
      ];
    }

    if (isTextSelection(selection)) {
      const currentBlock = getSelectedBlock(content, selection);
      if (!currentBlock) return;

      const result = generateBlockEditor(currentBlock).cut(selection.offset);
      if (!result) return;

      const contentPatch = result[0];

      return [
        {
          content: replaceBlockAt(
            content,
            contentPatch.contentSubset,
            selection.index,
          ),
          selection: applySelection(selection, contentPatch.selection),
        },
        result[1],
      ];
    }

    return;
  };

export const zeckEditorCut = cut<HydratedBlock>((block) => {
  if (isTextBlock(block)) {
    return {
      cut: (selection) => {
        const result = textBlockReplaceSelectedContentStrategy(
          block,
          selection,
          {
            type: 'text',
            content: [],
          },
        );
        const selectedContent = textBlockGetSelectedContentStrategy(
          block,
          selection,
        );

        if (!result || !selectedContent) return;

        return [result, selectedContent];
      },
    };
  }
  return {
    cut: () => {
      return [
        {
          contentSubset: [createParagraphBlock([])],
          selection: textSelection(0, contentSelection(0)),
        },
        { type: 'block', content: [block] },
      ];
    },
  };
}, createParagraphBlock);
