import styles from './EditorCommentForm.module.scss';
import { useState } from 'react';
import cx from 'classnames';
import CommentEditor from '../../../../../commentEditor/CommentEditor.ts';
import { CommentContentNode } from 'editor-content/CommentContent.js';
import ButtonWithIcon from '../../../../../design-system/atoms/ButtonWithIcon.tsx';
import LoadingButton from '../../../../../design-system/molecules/LoadingButton.tsx';
import CommentEditable from '../../../../../commentEditor/CommentEditable.js';

type CommentFormProps = {
  autofocus?: boolean;
  onSubmit: (content: CommentContentNode[]) => Promise<void>;
  placeholder: string;
  initialContent?: CommentContentNode[];
  submitText: string;
  availableTags: { userId: string; displayName: string }[];
  className?: string;
  scrollContainerRef: React.RefObject<HTMLElement>;
};

const EditorCommentForm: React.FC<CommentFormProps> = ({
  autofocus,
  onSubmit,
  placeholder,
  initialContent = [{ type: 'text', text: '' }],
  submitText,
  availableTags,
  className,
  scrollContainerRef,
}) => {
  const [commentContent, setCommentContent] = useState(initialContent);
  const [isSubmitting, setIsSubmitting] = useState(false);

  async function submit() {
    setIsSubmitting(true);
    await onSubmit(commentContent);
    setIsSubmitting(false);
    setCommentContent([{ type: 'text', text: '' }]);
  }

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();
        await submit();
      }}
      className={cx(className, styles.commentForm)}
    >
      <CommentEditable
        aria-label="comment-input"
        autofocus={autofocus}
        commentContent={commentContent}
        onChange={setCommentContent}
        placeholder={placeholder}
        onPressEnter={submit}
        availableTags={availableTags}
        tagTypeDisplayName="Section Contributors"
        className={styles.commentForm__input}
        scrollContainerRef={scrollContainerRef}
      />
      {isSubmitting ? (
        <LoadingButton className={styles.commentForm__action} />
      ) : (
        <ButtonWithIcon
          size="medium"
          color="primary"
          type="submit"
          iconName="keyboardReturn"
          position="right"
          disabled={
            !CommentEditor.canSubmit({
              content: commentContent,
              selection: null, //oop
            })
          }
          className={styles.commentForm__action}
        >
          {submitText}
        </ButtonWithIcon>
      )}
    </form>
  );
};

export default EditorCommentForm;
