import HoverNextToPoint from '../../../domHelpers/hoverNextTo/HoverNextToPoint.tsx';
import ImageUploadModal from '../edit/image/ImageUploadModal.tsx';
import { Company, User } from '../../../types.ts';
import ImageFormattingMenu from '../../../design-system/organisms/ImageFormattingMenu.tsx';
import CommentsMenu from './comments/components/CommentsMenu.tsx';
import { useState } from 'react';
import SelectionCommentWithActions from './comments/SelectionCommentWithActions.ts';
import { isSameSelection } from '../../../SelectionComment.ts';
import { ImageBlock } from 'editor-content/Block.js';
import { CommentContentNode } from 'editor-content/CommentContent.js';
import Toast from '../../../design-system/molecules/toast/Toast.tsx';
import useToast from '../../../design-system/molecules/toast/useToast.ts';

type ImageFormattingExperienceProps = {
  block: ImageBlock;
  getEl: () => HTMLElement | undefined;
  company: Pick<Company, 'id'>;
  onSetImageWidth: (width: ImageBlock['width']) => void;
  onSetImageAlign: (align: ImageBlock['align']) => void;
  onReplaceImage: (imageId: string, width: number, height: number) => void;
  onDeleteImage: () => void;
  onAddSelectionComment: (
    blockId: string,
    commentText: CommentContentNode[],
  ) => Promise<void>;
  user: User;
  selectionComments: SelectionCommentWithActions[];
  zeckId: string;
  sectionId: string;
};

type BlockInteractiveRenderState =
  | 'formatting'
  | 'replacing-image'
  | 'commenting';

export const canAlignImageWidth = (width: number): boolean => {
  // 700 max width of column
  return width < 700;
};

const canAlignBlockImage = (block: ImageBlock): boolean => {
  return (
    !!block?.dimensions?.width &&
    canAlignImageWidth(block.dimensions.width) &&
    block.width === 'column'
  );
};

const ImageFormattingExperience: React.VFC<ImageFormattingExperienceProps> = ({
  getEl,
  block,
  company,
  onSetImageWidth,
  onSetImageAlign,
  onReplaceImage,
  onDeleteImage,
  onAddSelectionComment,
  user,
  selectionComments,
  zeckId,
  sectionId,
}) => {
  const [interactiveState, setInteractiveState] =
    useState<BlockInteractiveRenderState>('formatting');

  const { showToast, ...toast } = useToast();

  return (
    <>
      {interactiveState === 'formatting' && (
        <HoverNextToPoint
          viewportPolicy="none"
          containerStyles={{ zIndex: 'initial' }}
          getPoint={(popoverEl) => {
            const targetEl = getEl();
            if (!targetEl) return [0, 0];

            const targetRect = targetEl.getBoundingClientRect();
            const popoverRect = popoverEl.getBoundingClientRect();
            return [
              targetRect.x + targetRect.width / 2 - popoverRect.width / 2,
              targetRect.y - popoverRect.height - 16,
            ];
          }}
          usePortal
        >
          <ImageFormattingMenu
            {...{
              onClickColumn: () => {
                onSetImageWidth('column');
              },
              onClickWide: () => {
                onSetImageWidth('wide');
              },
              onClickFullWidth: () => {
                onSetImageWidth('full-width');
              },
              onClickReplaceImage: () => {
                setInteractiveState('replacing-image');
              },
              onClickDeleteImage: () => {
                onDeleteImage();
              },
              onClickComment: () => {
                setInteractiveState('commenting');
              },
              onClickLeftAlign: () => {
                onSetImageAlign('left');
              },
              onClickCenterAlign: () => {
                onSetImageAlign('center');
              },
              columnActive: block.width === 'column',
              wideActive: block.width === 'wide',
              fullWidthActive: block.width === 'full-width',
              leftAlignActive: block.align === 'left',
              centerAlignActive:
                block.align === 'center' || typeof block.align === 'undefined',
              canAlign: canAlignBlockImage(block),
            }}
          />
        </HoverNextToPoint>
      )}
      {interactiveState === 'commenting' && (
        <HoverNextToPoint
          usePortal
          getPoint={(popoverEl) => {
            const targetEl = getEl();
            if (!targetEl) return [0, 0];

            const targetRect = targetEl.getBoundingClientRect();
            const popoverRect = popoverEl.getBoundingClientRect();
            return [
              targetRect.x + targetRect.width / 2 - popoverRect.width / 2,
              targetRect.y - popoverRect.height + 24,
            ];
          }}
        >
          <CommentsMenu
            user={user}
            comments={selectionComments.filter((selectionComment) =>
              isSameSelection(selectionComment, block),
            )}
            onPostComment={(content) =>
              onAddSelectionComment(block.id, content)
            }
            autofocus
            companyId={company.id}
            zeckId={zeckId}
            sectionId={sectionId}
          />
        </HoverNextToPoint>
      )}
      <ImageUploadModal
        isOpen={interactiveState === 'replacing-image'}
        company={company}
        onUploadSuccess={({ guid, width, height }) => {
          setInteractiveState('formatting');
          onReplaceImage(guid, width, height);
        }}
        onClose={() => {
          setInteractiveState('formatting');
        }}
        showToast={showToast}
      />
      <Toast {...toast} />
    </>
  );
};

export default ImageFormattingExperience;
