import { UserAndCompany } from '../../../../../../userAndCompany/FetchUserAndCompany.js';
import { CommentContent } from 'editor-content/CommentContent.js';
import isEqual from 'lodash/isEqual.js';
import useApi from '../../../../../../api/useApi.js';
import { Comment, CommentReply } from 'api-client/types.js';

const useActionsForComments = (
  userAndCompany: UserAndCompany,
  {
    setComment,
    setCommentReply,
    removeComment,
    resolve,
    unresolve,
    toggleStar,
  }: {
    setComment: (comment: Comment) => void;
    setCommentReply: (comment: CommentReply) => void;
    removeComment: (comment: { id: string }) => void;
    resolve: (comment: { id: string; resolved: boolean }) => void;
    unresolve: (comment: { id: string }) => void;
    toggleStar: (comment: { id: string }) => void;
  },
) => {
  const {
    deleteComment: deleteComment,
    updateComment: updateComment,
    createCommentReply,
    updateCommentReply: updateCommentReply,
    deleteCommentReply: deleteCommentReply,
    resolveComment,
    unresolveComment,
    unStarComment,
    starComment,
  } = useApi();

  const getActionsForComment = (comment: Comment) => {
    const edit = async (content: CommentContent) => {
      if (isEqual(content, comment.content)) return;

      const updatedComment = await updateComment(comment, content);

      setComment(updatedComment);
    };

    const deleteAction = async () => {
      await deleteComment(comment);

      removeComment(comment);
    };

    const resolveAction = async () => {
      resolve({ id: comment.id, resolved: true });

      await resolveComment(comment.id, comment.type);
    };

    const unresolveAction = async () => {
      await unresolveComment(comment.id, comment.type);

      unresolve({ id: comment.id });
    };

    const toggleStarAction = async () => {
      const storeStarredState = comment.starred ? unStarComment : starComment;

      await storeStarredState(comment.id, comment.type);

      toggleStar({ id: comment.id });
    };

    const reply = async (content: CommentContent) => {
      const reply = await createCommentReply(comment, content);

      const commentWithNewReply: Comment = {
        ...comment,
        replies: [...comment.replies, reply],
      };

      setComment(commentWithNewReply);
    };

    const isAuthor = comment.userId === userAndCompany.user.id;
    const isResolved = comment.resolved;
    const canEdit = isAuthor && !isResolved;
    const canDelete =
      isAuthor || userAndCompany.activeCompany.permissions.canManageZecks;
    const canResolve = !isResolved;
    const canUnresolve = isResolved;

    return {
      edit: canEdit ? edit : undefined,
      delete: canDelete ? deleteAction : undefined,
      reply,
      resolve: canResolve ? resolveAction : undefined,
      unresolve: canUnresolve ? unresolveAction : undefined,
      toggleStar: toggleStarAction,
    };
  };

  const getActionsForCommentReply = (commentReply: CommentReply) => {
    const edit = async (content: CommentContent) => {
      if (isEqual(content, commentReply.content)) return;

      const updatedComment = await updateCommentReply(commentReply, content);
      setCommentReply(updatedComment);
    };

    const deleteReply = async () => {
      await deleteCommentReply(commentReply);
      removeComment(commentReply);
    };

    const isAuthor = commentReply.userId === userAndCompany.user.id;
    if (isAuthor) {
      return {
        edit,
        delete: deleteReply,
      };
    }

    if (userAndCompany.activeCompany.permissions.canManageZecks) {
      return {
        delete: deleteReply,
      };
    }

    return {};
  };
  return { getActionsForComment, getActionsForCommentReply };
};

export default useActionsForComments;
