import styles from './AddBlockMenuItem.module.scss';
import { Icon, IconName } from 'icons';

type AddBlockMenuItemProps = {
  iconName: IconName;
  label: string;
  description: string;
};

const AddBlockMenuItem = ({
  iconName,
  label,
  description,
  ...otherProps
}: AddBlockMenuItemProps & React.HTMLAttributes<HTMLButtonElement>) => {
  return (
    <button {...otherProps} className={styles.addBlockMenuItem}>
      <div className={styles.addBlockMenuItem_image}>
        <Icon name={iconName} />
      </div>
      <div className={styles.addBlockMenuItem_text}>
        <div className={styles.addBlockMenuItem_label}>{label}</div>
        <div className={styles.addBlockMenuItem_description}>{description}</div>
      </div>
    </button>
  );
};

export default AddBlockMenuItem;
