import styles from './ViewerEngagementSummary.module.scss';

type ViewerEngagementChartProps = {
  percentViewed: number;
};

const PieChart = ({ unviewedPercent }: { unviewedPercent: number }) => {
  const r = 40; // radius of the circle
  const cx = 50; // x-coordinate of the center of the circle
  const cy = 50; // y-coordinate of the center of the circle

  if (unviewedPercent === 0) {
    return (
      <svg
        height="100"
        width="100"
        viewBox="0 0 100 100"
        className={styles.activePieSlice}
      >
        <circle cx={cx} cy={cy} r={r} fill={styles.activePieSlice} />

        <circle cx={cx} cy={cy} r={r / 1.66} fill="#fafafc" />
      </svg>
    );
  }

  if (unviewedPercent === 100) {
    return (
      <svg height="100" width="100" viewBox="0 0 100 100">
        <circle cx={cx} cy={cy} r={r} fill="#c7c9d9" />

        <circle cx={cx} cy={cy} r={r / 1.66} fill="#fafafc" />
      </svg>
    );
  }

  const x1 =
    cx + r * Math.cos((2 * Math.PI * unviewedPercent) / 100 - Math.PI / 2);
  const y1 =
    cy + r * Math.sin((2 * Math.PI * unviewedPercent) / 100 - Math.PI / 2);

  const x2 = cx + r * Math.cos(-Math.PI / 2);
  const y2 = cy + r * Math.sin(-Math.PI / 2);

  const largeArcFlag1 = unviewedPercent > 50 ? 1 : 0;
  const largeArcFlag2 = unviewedPercent < 50 ? 1 : 0;

  const d1 = `M ${cx} ${cy} L ${x2} ${y2} A ${r} ${r} 0 ${largeArcFlag1} 1 ${x1} ${y1} Z`;
  const d2 = `M ${cx} ${cy} L ${x1} ${y1} A ${r} ${r} 0 ${largeArcFlag2} 1 ${x2} ${y2} Z`;

  return (
    <svg height="100" width="100" viewBox="0 0 100 100" fill="pink">
      <path d={d1} fill="#c7c9d9" />
      <path d={d2} className={styles.activePieSlice} />
      <circle cx={cx} cy={cy} r={r / 1.66} fill="#fafafc" />{' '}
    </svg>
  );
};

const ViewerEngagementChart: React.FC<ViewerEngagementChartProps> = ({
  percentViewed,
}) => {
  const unviewedPercent = 100 - percentViewed;

  return (
    <>
      <PieChart unviewedPercent={unviewedPercent} />
      <div>
        <div className={styles.percentLabel} data-testid="viewed-percent">
          {percentViewed}% Viewed
        </div>

        <div className={styles.percentLabel} data-testid="unviewed-percent">
          {unviewedPercent}% Not Viewed
        </div>
      </div>
    </>
  );
};

export default ViewerEngagementChart;
