import styles from './BrandKitTile.module.scss';
import cx from 'classnames';
import { Icon } from 'icons';
import useAllowsInsideClick from '../../services/useAllowsInsideClick.tsx';
import ReactChildrenProp from '../../types/ReactChildrenProp.ts';

export type BrandKitTileProps<Data> = {
  className?: string;
  data: Data | null;
  label: string;
  onClick(): void;
  onClickDelete(): void;
  children: ReactChildrenProp;
};

export default function BrandKitTile<Data>({
  className,
  label,
  data,
  onClick,
  onClickDelete,
  children,
}: BrandKitTileProps<Data>) {
  const [ref, onClickContainer] =
    useAllowsInsideClick<HTMLButtonElement>(onClick);
  return (
    <button
      type="button"
      onClick={onClickContainer}
      ref={ref}
      className={cx(styles.brandKitTile, className)}
    >
      <div className={styles.brandKitTile__picture}>
        {children}
        {data ? (
          <div className={styles.brandKitTile__deleteOverlay}>
            {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
            <a
              className={styles.brandKitTile__deleteButton}
              type="button"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                onClickDelete();
              }}
              aria-label={`Remove ${label}`}
            >
              <Icon name="trash" />
            </a>
          </div>
        ) : (
          <div className={styles.brandKitTile__plus}>
            <Icon name="plus" />
          </div>
        )}
      </div>
      <div className={styles.brandKitTile__label}>{label}</div>
    </button>
  );
}
