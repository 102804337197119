import { IconName } from 'icons';
import React, { useEffect, useState } from 'react';
import { ElementAndData } from '../../junkDrawer/useElementAndDataArray.js';
import AddBlockButtonWithHover from './AddBlockButtonWithHover.js';
import AddingBlockMenu from './AddingBlockMenu.js';
import { compact } from 'lodash';
import AddBlockMenuSeparator from '../../design-system/atoms/AddBlockMenuSeparator.js';
import AddBlockMenuItem from '../../design-system/atoms/AddBlockMenuItem.js';
import { AnimatePresence, motion } from 'framer-motion';

const AddingBlockMenuWithItems = <BlockType extends { id: string }>({
  targetBlockId,
  blocksWithEl,
  addBlockOptions,
  onPickBlockTypeSimple,
  onPickBlockTypeComplex,
  onCancel,
}: {
  targetBlockId: string;
  blocksWithEl: ElementAndData<BlockType>[];
  addBlockOptions: (AddBlockItem<BlockType> | false | null | undefined)[];
  onPickBlockTypeSimple: (newContent: BlockType[]) => void;
  onPickBlockTypeComplex: (blockType: string) => void;
  onCancel: () => void;
}) => {
  return (
    <AddingBlockMenu
      blocksWithEl={blocksWithEl}
      targetBlockId={targetBlockId}
      onCancel={onCancel}
      addableBlocks={compact(addBlockOptions).map((value) => {
        switch (value.type) {
          case 'separator':
            return (
              <AddBlockMenuSeparator key={value.label}>
                {value.label}
              </AddBlockMenuSeparator>
            );

          case 'block':
            return (
              <AddBlockMenuItem
                key={value.label}
                iconName={value.iconName}
                onClick={() => {
                  onPickBlockTypeSimple(value.createNewBlock());
                }}
                label={value.label}
                description={value.description}
              />
            );
          case 'complex-block':
            return (
              <AddBlockMenuItem
                key={value.label}
                iconName={value.iconName}
                onClick={() => {
                  onPickBlockTypeComplex(value.blockType);
                }}
                label={value.label}
                description={value.description}
              />
            );
        }
      })}
    />
  );
};

type UIState =
  | { type: 'hovering' }
  | {
      type: 'picking';
      blockId: string;
    }
  | {
      type: 'adding';
      blockId: string;
      blockType: string;
    };

type ComplexBlockItem<BlockType> = {
  type: 'complex-block';
  label: string;
  description: string;
  blockType: string;
  iconName: IconName;
  renderAdding: (props: {
    onAdd: (block: BlockType[]) => void;
    onCancel: () => void;
  }) => React.ReactNode;
};

type SimpleBlockItem<BlockType> = {
  type: 'block';
  label: string;
  description: string;
  blockType: string;
  iconName: IconName;
  createNewBlock: () => BlockType[];
};

type SeparatorItem = { type: 'separator'; label: string };

export type AddBlockItem<BlockType> =
  | SimpleBlockItem<BlockType>
  | ComplexBlockItem<BlockType>
  | SeparatorItem;

const AddBlockExperience = <BlockType extends { id: string }>({
  blocksWithEl,
  leftGutterRef,
  onAddNewBlock,
  onReplaceNewBlock,
  onConfigureComplexBlock,
  addBlockOptions,
}: {
  blocksWithEl: ElementAndData<BlockType>[];
  leftGutterRef: React.RefObject<HTMLElement>;
  onAddNewBlock: (targetIndex: number) => string;
  onReplaceNewBlock: (newBlock: BlockType[], targetBlockId: string) => void;
  onConfigureComplexBlock: () => void;
  addBlockOptions: (AddBlockItem<BlockType> | false | null | undefined)[];
}) => {
  const [uiState, setUIState] = useState<UIState>({ type: 'hovering' });

  useEffect(() => {
    if (uiState.type === 'adding') {
      onConfigureComplexBlock();
    }
  }, [uiState.type, onConfigureComplexBlock]);

  function close() {
    setUIState({ type: 'hovering' });
  }

  return (
    <>
      <AnimatePresence>
        {uiState.type === 'hovering' && (
          <motion.div
            key="hovering"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.2 } }}
            exit={{ opacity: 0, transition: { duration: 0.1 } }}
          >
            <AddBlockButtonWithHover
              blocksWithEl={blocksWithEl}
              leftGutterRef={leftGutterRef}
              onAddNewBlock={(index) => {
                const newBlockId = onAddNewBlock(index);
                setUIState({
                  type: 'picking',
                  blockId: newBlockId,
                });
              }}
            />
          </motion.div>
        )}
        {uiState.type === 'picking' && (
          <motion.div
            key="picking"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <AddingBlockMenuWithItems
              blocksWithEl={blocksWithEl}
              addBlockOptions={addBlockOptions}
              targetBlockId={uiState.blockId}
              onCancel={close}
              onPickBlockTypeComplex={(blockType) => {
                setUIState({
                  type: 'adding',
                  blockId: uiState.blockId,
                  blockType,
                });
              }}
              onPickBlockTypeSimple={(content) => {
                onReplaceNewBlock(content, uiState.blockId);
                close();
              }}
            />
          </motion.div>
        )}
      </AnimatePresence>
      {uiState.type === 'adding' &&
        (compact(addBlockOptions)
          .find(
            (blockConfig): blockConfig is ComplexBlockItem<BlockType> =>
              blockConfig.type === 'complex-block' &&
              blockConfig.blockType === uiState.blockType,
          )
          ?.renderAdding({
            onAdd: (block) => {
              onReplaceNewBlock(block, uiState.blockId);
              close();
            },
            onCancel: close,
          }) ??
          null)}
    </>
  );
};

export default AddBlockExperience;
