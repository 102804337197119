import React, { useState } from 'react';
import {
  CommentContent,
  CommentContentNode,
} from 'editor-content/CommentContent.js';
import styles from './CommentOrReply.module.scss';
import CommentEditorAdapter from '../../../../../../commentEditor/CommentEditorAdapter.js';
import CommentKebab from './../CommentKebab.js';
import PublishedCommentForm from './../PublishedCommentForm.js';
import CommentNameplate from './../CommentNameplate.js';
import PublishedCommentLayout from './../PublishedCommentLayout.tsx';
import IconButton from '../../../../../../design-system/atoms/IconButton.tsx';
import WithTooltip from '../../../../../../design-system/organisms/WithTooltip.tsx';

export const CommentOrReply: React.FC<{
  className?: string;
  userName: string;
  content: CommentContentNode[];
  commentTypeLabel: string;
  isReply: boolean;
  createdAt: number;
  updatedAt: number;
  starred?: boolean;
  onEdit?: (content: CommentContent) => Promise<void>;
  onDelete?: () => void;
  onResolve?: () => void;
  onUnresolve?: () => void;
  onToggleStar?: () => void;
}> = ({
  className,
  userName,
  content,
  commentTypeLabel,
  isReply,
  createdAt,
  updatedAt,
  starred,
  onEdit,
  onDelete,
  onResolve,
  onUnresolve,
  onToggleStar,
  ...props
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const showStar = !onUnresolve;

  const starredTooltip = starred ? 'Starred' : 'Not Starred';
  return (
    <PublishedCommentLayout className={className} {...props}>
      <CommentNameplate
        userName={userName}
        timestamp={createdAt}
        actionsSlot={
          <>
            {!isReply && onResolve && (
              <WithTooltip<HTMLButtonElement> text="Mark as resolved and hide">
                {(ref, listeners) => (
                  <IconButton
                    name="checkmark"
                    aria-label="resolve"
                    className={`${styles.commentOrReply__resolveButton} exposeOnAncestorHover`}
                    onClick={onResolve}
                    ref={ref}
                    {...listeners}
                  />
                )}
              </WithTooltip>
            )}
            {showStar && (
              <WithTooltip<HTMLButtonElement> text={starredTooltip}>
                {(ref, listeners) => (
                  <IconButton
                    name={starred ? 'yellowStar' : 'star'}
                    aria-label="star"
                    className={`${starred ? styles.commentOrReply__starButton__starred : styles.commentOrReply__starButton__unstarred} exposeOnAncestorHover`}
                    onClick={onToggleStar}
                    ref={ref}
                    {...listeners}
                  />
                )}
              </WithTooltip>
            )}

            <CommentKebab
              commentTypeLabel={commentTypeLabel}
              onUnresolve={onUnresolve}
              onClickEdit={
                onEdit &&
                (() => {
                  setIsEditing(true);
                })
              }
              onDelete={onDelete}
            />
          </>
        }
      />
      {isEditing ? (
        <PublishedCommentForm
          className={styles.commentOrReply__editForm}
          availableTags={[]}
          onCancel={() => setIsEditing(false)}
          onSubmit={async (value) => {
            if (!onEdit) return;
            await onEdit(value.content);
            setIsEditing(false);
          }}
          testId="edit-comment"
          initialValue={{
            isDirectMessage: false,
            content,
          }}
          canToggleDirectMessage={false}
          submitText="Save"
        />
      ) : (
        <p className={styles.commentOrReply__content}>
          <span
            dangerouslySetInnerHTML={{
              __html: CommentEditorAdapter.toHTMLString(content),
            }}
          />
          {updatedAt > createdAt && (
            <span className={styles.commentOrReply__edited}> (edited)</span>
          )}
        </p>
      )}
    </PublishedCommentLayout>
  );
};
