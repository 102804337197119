import useApi from '../../../../../api/useApi.ts';
import { isSuccessResult } from '../../../../../result/Result.ts';
import { sequenceSResult } from '../../../../../result/sequenceSResult.ts';
import useFetch from '../../../../../services/useFetch/useFetch.ts';
import {
  PublishedSection,
  UserAndPermission,
  Zeck,
} from '../../../../../types.ts';

import {
  AnalyticsSummary,
  HydratedEngagementTotal,
  UserEngagementTotal,
  ZeckUserView,
} from 'app/api/endpoints/createAnalyticsApi.ts';
import getUserZeckEngagementTotals from './getUserZeckEngagementTotals.ts';
import getViewerEngagementSummary from './getViewerEngagementSummary.ts';

const filterAndHydrateEngagementTotals = (
  engagementTotals: UserEngagementTotal[],
  users: UserAndPermission[],
) => {
  const totals: HydratedEngagementTotal[] = [];
  engagementTotals.forEach((total) => {
    const user = users.find((u) => u.id === total.userId && isValidViewer(u));
    if (user) {
      totals.push({
        ...total,
        user,
      });
    }
  });
  return totals;
};

const isValidViewer = (user: UserAndPermission) => user.role === 'viewer';

type UseAnalyticsProps = {
  users: UserAndPermission[];
  zeck: Readonly<
    Pick<Zeck, 'id' | 'companyId' | 'firstPublishedAt'> & {
      publishedZeck: {
        sections: PublishedSection[];
      } | null;
    }
  >;
};

export const generateAnalyticsSummary = (
  userEngagementTotals: UserEngagementTotal[],
  users: UserAndPermission[],
  zeckViews: ZeckUserView[],
): AnalyticsSummary => {
  const hyrdatedTotals = filterAndHydrateEngagementTotals(
    userEngagementTotals,
    users,
  );

  const filteredZeckViews = zeckViews.filter((view) =>
    users.find((u) => u.id === view.userId),
  );
  const { userTotals } = getUserZeckEngagementTotals(hyrdatedTotals);
  const validViewerCount = users.filter(isValidViewer).length;

  return {
    engagementTotals: hyrdatedTotals,
    userTotals,
    zeckViews: filteredZeckViews,
    engagementSummary: getViewerEngagementSummary(userTotals, validViewerCount),
    getUserView(userId: string) {
      return filteredZeckViews.find((view) => view.userId === userId);
    },
  };
};

const useAnalyticsData = ({
  zeck,
  users,
}: Pick<UseAnalyticsProps, 'zeck' | 'users'>): AnalyticsSummary | null => {
  const api = useApi();

  const userEngagementResult = useFetch(() => {
    return api.getZeckEngagementTotals(zeck.id);
  }, [zeck.id]);

  const zeckViewResult = useFetch(() => {
    return api.getZeckAnalytics(zeck.id);
  }, [zeck.id]);

  const combinedResult = sequenceSResult({
    zeckViews: zeckViewResult,
    userEngagementTotals: userEngagementResult,
  });

  if (!isSuccessResult(combinedResult)) {
    return null;
  }

  return generateAnalyticsSummary(
    combinedResult.data.userEngagementTotals,
    users,
    combinedResult.data.zeckViews,
  );
};

export default useAnalyticsData;
