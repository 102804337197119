import { isError } from 'lodash';
import useApi from '../api/useApi.ts';
import { ErrorWithContext, logError, logInfo } from '../logging/logger.ts';
import { getBase64 } from '../pages/zeck/edit/image/getImageDimensions.ts';

export const IMAGE_FILE_TYPES = [
  'image/png',
  'image/jpg',
  'image/jpeg',
  'image/gif',
  'image/webp',
  'image/svg+xml',
];

const useImageUpload = () => {
  const { createImage, uploadImageFile, aiDescribeImage } = useApi();

  return async (
    file: File,
    companyId: string,
    errorCb: (message: string) => void,
    onUploadSuccess: (image: {
      guid: string;
      width: number;
      height: number;
    }) => void,
  ) => {
    const onError = (message: string, logString: string) => {
      logInfo('Image Upload Error: ' + message + ' ' + logString, {
        fingerprint: [message],
      });
      errorCb(message);
    };

    if (file.size > 50 * 1000 * 1000) {
      return onError(
        'Uploaded images must be less than 50MB. Sorry for being so mean about it.',
        'File size: ' + file.size,
      );
    }

    if (!IMAGE_FILE_TYPES.includes(file.type)) {
      return onError(
        "This image type isn't supported, please select different one.",
        'File type: ' + file.type,
      );
    }

    try {
      const {
        dimensions: { width, height },
      } = await getBase64(file);

      const { imageId, writeUrl } = await createImage({
        companyId,
        contentType: file.type,
        filename: file.name,
      });

      try {
        await uploadImageFile(writeUrl, file);
      } catch (e) {
        return onError(
          'There was an issue uploading this image, please try again.',
          'Error: ' + (e as Error)?.message || '(unknown error message)',
        );
      }

      await aiDescribeImage(imageId).catch((e) => {
        // non-critical failure, just log it
        logError(
          new ErrorWithContext(
            'failed to ai describe image',
            {
              imageId,
              sourceError: e.message,
            },
            'aiDescribeImage',
          ),
        );
      });

      onUploadSuccess({ guid: imageId, width, height });
    } catch (e) {
      let errorMessage = '(unknown error message)';
      if (isError(e)) {
        errorMessage = e.message;
      } else if (typeof e === 'string') {
        errorMessage = e;
      }

      return onError(
        'There was an issue processing this image, please select different one.',
        'Error: ' + errorMessage,
      );
    }
  };
};
export default useImageUpload;
