import styles from './CommentVisibilityHelp.module.scss';
import { Icon } from 'icons';
import SpeechBubble from '../../../../../design-system/molecules/SpeechBubble.tsx';
import { MaybePendingUser } from '../../../../../types.ts';

const getTooltipStringFromViewer = (viewer: MaybePendingUser) => {
  if (viewer.pending) {
    return viewer.email;
  }

  return `${viewer.firstName} ${viewer.lastName[0]}`;
};

type CommentVisibilityHelpProps = {
  viewers: MaybePendingUser[];
};

const CommentVisibilityHelp: React.FC<CommentVisibilityHelpProps> = ({
  viewers,
}) => {
  return (
    <div
      data-testid="comment-visibility-help"
      className={styles.comments__newComment__who}
    >
      <Icon
        name="secure"
        className={styles.comments__newComment__who__secureIcon}
      />{' '}
      <span className={styles.comments__newComment__who__description}>
        Who Can See This?
      </span>{' '}
      <span className={styles.comments__newComment__who__people}>
        {viewers.length} People
      </span>{' '}
      <Icon
        name="help"
        className={styles.comments__newComment__who__helpIcon}
      />
      <SpeechBubble
        data-testid="comment-visibility-help-tooltip"
        className={styles.comments__newComment__who__tooltip}
      >
        <span className={styles.comments__newComment__who__tooltip__names}>
          {viewers.map((u) => getTooltipStringFromViewer(u)).join(', ')}
        </span>{' '}
        <span>will see your comment.</span>
      </SpeechBubble>
    </div>
  );
};

export default CommentVisibilityHelp;
