import {
  isCollapsedSelection,
  isTextSelection,
  textSelection,
  TextSelection,
} from '../../../../editor/selection/TextSelection.js';
import addLinkToTextNodes from './addLinkToTextNodes.js';
import identity from '../../../../junkDrawer/identity.js';
import addHighlightToTextNodes from './addHighlightToTextNodes.js';
import {
  contentSelection,
  getEndOfSelection,
} from '../../../../editor/selection/contentSelection/ContentSelection.js';
import {
  createImageBlock,
  createParagraphBlock,
  FileBlock,
  ImageBlock,
  isTextBlock,
  updateTextBlock,
} from 'editor-content/Block.js';
import { Editor, Text } from 'editor-content/TextNode.js';
import cond from '../../../../junkDrawer/cond.js';
import guard from '../../../../junkDrawer/guard.js';
import pasteBlocks from './pasteBlocks.js';
import turnInto from './turnInto.js';
import pressEnter from '../pressEnter/pressEnterBody.js';
import indent from './indent.js';
import toggleFormat from './toggleFormat.js';
import getEndOfBlock from '../../../../editor/blocks/textBlocksStrategies/getEndOfBlock.js';
import {
  BlockSelection,
  isBlockSelection,
} from '../../../../editor/selection/BlockSelection.js';
import textBlockReplaceSelectedContentWithPlaintextStrategy from '../../../../editor/blocks/textBlocksStrategies/textBlockReplaceSelectedContentWithPlaintextStrategy.js';
import replaceSelectionWith from './replaceSelectionWith.js';
import { canAlignImageWidth } from '../ImageFormattingExperience.js';
import pressShiftArrowUp from '../../../../editor/actions/pressShiftArrowUp.js';
import textBlockPressShiftArrowUpStrategy from '../../../../editor/blocks/textBlocksStrategies/textBlockPressShiftArrowUpStrategy.js';
import pressShiftArrowDown from '../../../../editor/actions/pressShiftArrowDown.js';
import textBlockPressShiftArrowDownStrategy from '../../../../editor/blocks/textBlocksStrategies/textBlockPressShiftArrowDownStrategy.js';
import { HydratedBlock } from '../../../../types/HydratedBlock.js';
import {
  hydratedIsFileBlock,
  hydratedIsImageBlock,
  hydratedIsTableBlock,
  hydratedIsTextBlock,
} from './hydratedBlockGuards.js';
import { TableBlock } from 'editor-content/TableBlock.js';
import editBlock from './editBlock.js';
import { zeckEditorCut } from './cut.js';
import { zeckEditorCopy } from './copy.js';
import pasteText from './pasteText.js';
import select from './select.js';
import insertAiContentAbove from './insertAiContentAbove.ts';
import addBlockAtEnd from './addBlockAtEnd.js';
import pressDeleteBody from './pressDeleteBody.js';
import pressBackspaceBody from './pressBackspaceBody.js';

export type BodySelection = TextSelection | BlockSelection;

export type BodyContent = HydratedBlock[];

export type BodyStateSelected = {
  content: BodyContent;
  selection: BodySelection;
};

export type BodyState = {
  content: BodyContent;
  selection: BodySelection | null;
};

export type BodyActionResult = BodyStateSelected | void;

export function textSelectionAtIndex(
  content: HydratedBlock[],
  index: number,
): TextSelection {
  const newSelectedBlock = content[index];

  if (!newSelectedBlock) {
    return textSelection(0, contentSelection(0));
  }

  return textSelection(index, getEndOfBlock(newSelectedBlock));
}

function navLeft(initialState: BodyStateSelected): BodyActionResult {
  const { content, selection } = initialState;

  if (isBlockSelection(selection)) {
    // nav left in block selection currently does nothing
    return { content, selection };
  }

  if (selection.index === 0) {
    return;
  }
  const newIndex = selection.index - 1;
  const block = content[newIndex];
  if (!block) {
    return;
  }

  return {
    content,
    selection: textSelection(newIndex, getEndOfBlock(block)),
  };
}

function navRight(initialState: BodyStateSelected): BodyActionResult {
  const { content, selection } = initialState;

  if (!isTextSelection(selection)) return { content, selection };

  const i = selection.index + 1;
  if (i >= content.length) return;
  if (!content[i]) {
    return { content, selection };
  }

  return {
    content,
    selection: textSelection(i, contentSelection(0)),
  };
}

const BodyEditor = {
  pasteText,
  pasteBlocks,
  pastePlaintext(
    initialState: BodyStateSelected,
    plainText: string,
  ): BodyStateSelected | void {
    return replaceSelectionWith(initialState, {
      textSelection(selectedBlock, selection) {
        if (!isTextBlock(selectedBlock)) return;

        return textBlockReplaceSelectedContentWithPlaintextStrategy(
          selectedBlock,
          selection,
          plainText,
        );
      },
      blockSelection() {
        const lines = plainText.split('\n');
        const lastLine = lines[lines.length - 1];

        if (!lastLine) return;

        const newBlocks = lines.map((s) => createParagraphBlock([Text(s)]));

        return {
          contentSubset: newBlocks,
          selection: textSelection(
            lines.length - 1,
            contentSelection(lastLine.length),
          ),
        };
      },
    });
  },
  cut: zeckEditorCut,
  copy: zeckEditorCopy,

  pressBackspace: pressBackspaceBody,
  pressDelete: pressDeleteBody,
  pressEnter,

  navLeft,
  navRight,

  selectUp(initialState: BodyStateSelected): BodyStateSelected {
    const result = pressShiftArrowUp((block) => {
      if (isTextBlock(block)) {
        return {
          pressShiftArrowUp: textBlockPressShiftArrowUpStrategy,
        };
      }

      return {
        pressShiftArrowUp: () => true,
      };
    }, initialState);

    return result || initialState;
  },
  selectDown(initialState: BodyStateSelected): BodyStateSelected {
    const result = pressShiftArrowDown(
      (block) => ({
        pressShiftArrowDown(selection) {
          if (isTextBlock(block)) {
            return textBlockPressShiftArrowDownStrategy(block, selection);
          }

          return true;
        },
      }),
      initialState,
    );

    return result || initialState;
  },

  toggleFormat,

  addLink(
    initialState: BodyStateSelected,
    link: Editor.LinkType,
  ): BodyStateSelected {
    const { content, selection } = initialState;
    if (isBlockSelection(selection)) return initialState;

    // it seems like isTextBlock type guard, the not case is making it a Block instead of keeping it HydratedBlock
    return {
      content: content.map(
        cond(
          (a, i) => i === selection.index,
          guard(
            hydratedIsTextBlock,
            (block) =>
              updateTextBlock(
                block,
                addLinkToTextNodes(block.content, selection.offset, link),
              ),
            identity,
          ),
          identity,
        ),
      ),
      selection,
    };
  },
  addHighlight(
    initialState: BodyStateSelected,
    highlightId: string,
  ): BodyStateSelected {
    const { content, selection } = initialState;
    if (isBlockSelection(selection)) return initialState;
    if (isCollapsedSelection(selection)) return initialState;

    return {
      content: content.map(
        cond(
          (a, i) => i === selection.index,
          guard(
            hydratedIsTextBlock,
            (block) =>
              updateTextBlock(
                block,
                addHighlightToTextNodes(
                  block.content,
                  selection.offset,
                  highlightId,
                ),
              ),
            identity,
          ),
          identity,
        ),
      ),
      selection: textSelection(
        selection.index,
        contentSelection(getEndOfSelection(selection.offset)),
      ),
    };
  },

  setImageWidth(
    initialState: BodyStateSelected,
    width: ImageBlock['width'],
  ): BodyStateSelected {
    const { content, selection } = initialState;

    if (!isTextSelection(selection)) return initialState;

    return {
      content: content.map(
        cond(
          (a, i) => i === selection.index,
          guard(
            hydratedIsImageBlock,
            (block) => ({
              ...block,
              width: width,
            }),
            identity,
          ),
          identity,
        ),
      ),
      selection,
    };
  },

  setImageAlign(
    initialState: BodyStateSelected,
    align: ImageBlock['align'],
  ): BodyStateSelected {
    const { content, selection } = initialState;

    if (!isTextSelection(selection)) return initialState;

    return {
      content: content.map(
        cond(
          (a, i) => i === selection.index,
          guard(
            hydratedIsImageBlock,
            (block) => ({
              ...block,
              align: align,
            }),
            identity,
          ),
          identity,
        ),
      ),
      selection,
    };
  },

  pasteImage(
    initialState: BodyStateSelected,
    imageGuid: string,
    width: number,
    height: number,
  ): BodyStateSelected | void {
    const newImageBlock = createImageBlock(imageGuid, '', 'column', 'center', {
      width,
      height,
    });

    return pasteBlocks(initialState, [newImageBlock]);
  },

  replaceImage(
    initialState: BodyStateSelected,
    newImageGuid: string,
    newImageWidth: number,
    newImageHeight: number,
  ): BodyStateSelected {
    const { content, selection } = initialState;
    if (!isTextSelection(selection)) return initialState;

    return {
      content: content.map(
        cond(
          (a, i) => i === selection.index,
          guard(
            hydratedIsImageBlock,
            (block) => ({
              ...block,
              align: !canAlignImageWidth(newImageWidth)
                ? 'center'
                : block.align,
              guid: newImageGuid,
              dimensions: {
                width: newImageWidth,
                height: newImageHeight,
              },
            }),
            identity,
          ),
          identity,
        ),
      ),
      selection,
    };
  },

  replaceFile(
    initialState: BodyStateSelected,
    newFile: Pick<FileBlock, 'guid' | 'filename'>,
  ): BodyStateSelected {
    const { content, selection } = initialState;
    if (!isTextSelection(selection)) return initialState;

    return {
      content: content.map(
        cond(
          (a, i) => i === selection.index,
          guard(
            hydratedIsFileBlock,
            (block) => ({
              ...block,
              guid: newFile.guid,
              filename: newFile.filename,
            }),
            identity,
          ),
          identity,
        ),
      ),
      selection,
    };
  },

  replaceTable(
    initialState: BodyStateSelected,
    newTable: Pick<TableBlock, 'data'>,
  ): BodyStateSelected {
    const { content, selection } = initialState;

    if (!isTextSelection(selection)) return initialState;

    return {
      content: content.map(
        cond(
          (a, i) => i === selection.index,
          guard(
            hydratedIsTableBlock,
            (block) => ({
              ...block,
              data: newTable.data,
            }),
            identity,
          ),
          identity,
        ),
      ),
      selection,
    };
  },

  turnInto,
  indent,

  editBlock,
  select,

  addBlockAtEnd,
  insertAiContentAbove,
};

export default BodyEditor;
