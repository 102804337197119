import ContentSelection, {
  contentSelection,
} from '../selection/contentSelection/ContentSelection.js';
import { textSelection, TextSelection } from '../selection/TextSelection.js';
import { BlockSelection } from '../selection/BlockSelection.js';
import insertItem from '../../junkDrawer/sortable/insertItem.js';
import replaceBlockAt from '../../pages/zeck/editor/BodyEditor/replaceBlockAt.js';
import { EditorStateGeneric } from '../EditorStateGeneric.js';
import BaseBlock from 'editor-content/BaseBlock.js';

type BodyStateSelected<BlockType> = {
  content: BlockType[];
  selection: TextSelection | BlockSelection;
};

export type AddBlockBlockEditorInterface = {
  isEmptyDefaultBlock: () => boolean;
  getEndOfBlockSelection: () => ContentSelection;
};

const addNewBlock =
  <BlockType>(
    generateBlockEditor: (
      b: BlockType,
    ) => Pick<AddBlockBlockEditorInterface, 'isEmptyDefaultBlock'>,
  ) =>
  (
    initialState: EditorStateGeneric<BlockType>,
    targetIndex: number,
    newBlock: BlockType,
  ): [newState: BodyStateSelected<BlockType>, void] => {
    const targetBlock = initialState.content[targetIndex];
    if (!targetBlock) throw new Error('tried to add new block out of range');

    if (generateBlockEditor(targetBlock).isEmptyDefaultBlock()) {
      return [
        {
          content: replaceBlockAt(
            initialState.content,
            [newBlock],
            targetIndex,
          ),
          selection: textSelection(targetIndex, contentSelection(0)),
        },
        undefined,
      ];
    }

    return [
      {
        content: insertItem(initialState.content, targetIndex + 1, newBlock),
        selection: textSelection(targetIndex + 1, contentSelection(0)),
      },
      undefined,
    ];
  };

const replaceNewBlock =
  <BlockType extends BaseBlock>(
    generateBlockEditor: (
      b: BlockType,
    ) => Pick<AddBlockBlockEditorInterface, 'getEndOfBlockSelection'>,
  ) =>
  (
    initialState: EditorStateGeneric<BlockType>,
    newContent: BlockType[],
    targetId: string,
  ): [newState: BodyStateSelected<BlockType>, returnValue: void] => {
    const targetBlockIndex = initialState.content.findIndex(
      (block) => block.id === targetId,
    );

    if (targetBlockIndex < 0) throw new Error('new block was deleted');

    const lastBlock = newContent[newContent.length - 1];

    return [
      {
        content: replaceBlockAt(
          initialState.content,
          newContent,
          targetBlockIndex,
        ),
        selection: textSelection(
          targetBlockIndex + newContent.length - 1,
          (lastBlock &&
            generateBlockEditor(lastBlock).getEndOfBlockSelection()) ??
            contentSelection(0),
        ),
      },
      undefined,
    ];
  };

const AddBlockEditorActions = {
  addNewBlock,
  replaceNewBlock,
};

export default AddBlockEditorActions;
