import { contentSelection } from './selection/contentSelection/ContentSelection.js';
import replaceBlocksAt from './blocks/replaceBlocksAt.js';
import { EditorSelection } from './EditorSelection.js';

import splitContentByBlockSelection from './selection/splitContentByBlockSelection.js';
import { CoordinatorEvent, PublicEditorEvent } from './EditorEvents.js';
import {
  BlockEditorClassToBlock,
  BlockEditorInterface,
} from './BaseBlockEditor.js';
import matchSelectionType from './selection/matchSelectionType.js';
import { isTextSelection, textSelection } from './selection/TextSelection.js';
import { createHistoryStore, HistoryStore } from './HistoryStore.js';
import {
  blockSelection,
  isBlockSelection,
} from './selection/BlockSelection.js';
import { TextNode } from 'editor-content/TextNode.js';
import blockSelectionReplaceSelectedContentWithPlaintext from './blockSelectionReplaceSelectedContentWithPlaintext.js';
import EditorData from '../pages/zeck/editor/EditorData.js';
import getEndOfBlock from './blocks/textBlocksStrategies/getEndOfBlock.js';
import { Block } from 'editor-content/Block.js';
import replaceSelectionWith from '../pages/zeck/editor/BodyEditor/replaceSelectionWith.js';
import arrayIs from '../junkDrawer/arrayIs.js';
import pressShiftArrowUp from './actions/pressShiftArrowUp.js';
import pressShiftArrowDown from './actions/pressShiftArrowDown.js';
import pressArrowDown from './actions/pressArrowDown.js';
import pressArrowUp from './actions/pressArrowUp.js';
import { EditorStateGeneric } from './EditorStateGeneric.js';
import pressEnter from './actions/pressEnter.js';
import pressShiftEnter from './actions/pressShiftEnter.js';
import AddBlockEditorActions from './addBlock/AddBlockEditorActions.js';
import pressDelete from '../pages/zeck/editor/BodyEditor/pressDelete.js';
import pressBackspace from '../pages/zeck/editor/BodyEditor/pressBackspace.js';

/**
 * This Editor is the coordinator in a PAC architecture.
 * A mediator between clients and block/editor specific objects that wrap the business logic
 */
export class EditorCoordinator<
  AvailableBlockEditors extends BlockEditorInterface<
    AvailableBlocks,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any
  >,
  AvailableBlocks extends
    Block = BlockEditorClassToBlock<AvailableBlockEditors>,
  DefaultBlock extends AvailableBlocks = AvailableBlocks,
> {
  #contentSubscriptions: Set<() => void> = new Set();

  #store: HistoryStore<AvailableBlocks[]>;

  #generateBlockEditor: (block: AvailableBlocks) => AvailableBlockEditors;
  createDefaultBlock: (content: TextNode[]) => DefaultBlock;

  #turnInto: (
    block: AvailableBlocks,
    type: AvailableBlocks['type'],
  ) => AvailableBlocks | void;

  constructor(
    initialState: {
      content: AvailableBlocks[];
      selection: EditorSelection;
    },
    configuration: {
      createDefaultBlock: (content: TextNode[]) => DefaultBlock;
      generateBlockEditor: (block: AvailableBlocks) => AvailableBlockEditors;
      turnInto: (
        block: AvailableBlocks,
        type: AvailableBlocks['type'],
      ) => AvailableBlocks | void;
    },
  ) {
    this.#store = createHistoryStore(initialState);
    this.#turnInto = configuration.turnInto;
    this.#generateBlockEditor = configuration.generateBlockEditor;
    this.createDefaultBlock = configuration.createDefaultBlock;
  }

  getState() {
    return this.#store.get();
  }

  setState(newState: {
    content: AvailableBlocks[];
    selection: EditorSelection;
  }) {
    this.#store.set(newState);
    this.#notifyContentSubscribers();
  }

  getSelectedContent(): EditorData<AvailableBlocks> | void {
    const currentState = this.#store.get();
    const selection = currentState.selection;
    const content = currentState.content;
    if (isTextSelection(selection)) {
      const selectedBlock = content[selection.index];

      if (!selectedBlock) return;

      const blockEditor = this.#generateBlockEditor(selectedBlock);

      const selectedContent = blockEditor.getSelectedContent(selection.offset);

      if (!selectedContent) return { type: 'block', content: [selectedBlock] };

      return {
        type: 'text',
        content: selectedContent,
      };
    } else if (isBlockSelection(selection)) {
      const [, selectedBlocks] = splitContentByBlockSelection(
        content,
        selection,
      );
      return {
        type: 'block',
        content: selectedBlocks,
      };
    }

    return;
  }

  subscribeToContentChanges(callback: () => void) {
    this.#contentSubscriptions.add(callback);
    return () => {
      this.#contentSubscriptions.delete(callback);
    };
  }

  #notifyContentSubscribers() {
    for (const callback of this.#contentSubscriptions) {
      callback();
    }
  }

  #applyActionResult(result: void | EditorStateGeneric<AvailableBlocks>) {
    if (!result) return false;
    this.#store.set(result);
    this.#notifyContentSubscribers();

    return true;
  }

  dispatch = (
    event:
      | PublicEditorEvent<AvailableBlocks>
      | CoordinatorEvent<AvailableBlocks>,
  ): boolean => {
    const currentState = this.#store.get();
    switch (event.type) {
      case 'selection': {
        this.#store.set({
          content: currentState.content,
          selection: event.data,
        });
        this.#notifyContentSubscribers();
        return true;
      }
      case 'replaceBlocks': {
        if (isTextSelection(event.data.contentPatch.selection)) {
          this.#store.set({
            content: replaceBlocksAt(
              currentState.content,
              event.data.contentPatch.contentSubset,
              event.data.index,
              event.data.blocksToReplace,
            ),
            selection: {
              index: event.data.index + event.data.contentPatch.selection.index,
              offset: event.data.contentPatch.selection.offset,
            },
          });
        } else {
          this.#store.set({
            content: replaceBlocksAt(
              currentState.content,
              event.data.contentPatch.contentSubset,
              event.data.index,
              event.data.blocksToReplace,
            ),
            selection: event.data.contentPatch.selection,
          });
        }

        this.#notifyContentSubscribers();
        return true;
      }
      case 'insertEmptyBlock': {
        this.#store.set({
          content: replaceBlocksAt(
            currentState.content,
            [this.createDefaultBlock([])],
            event.data.index,
            0,
          ),
          selection: {
            index: event.data.index,
            offset: contentSelection(0),
          },
        });

        this.#notifyContentSubscribers();
        return true;
      }
      case 'undo': {
        this.#store.undo();
        this.#notifyContentSubscribers();
        return true;
      }
      case 'redo': {
        this.#store.redo();
        this.#notifyContentSubscribers();
        return true;
      }
      case 'replaceSelectedContent': {
        if (currentState.selection == null) return false;

        const result = replaceSelectionWith(currentState, {
          textSelection: (selectedBlock, selection) => {
            const targetBlockEditor = this.#generateBlockEditor(selectedBlock);

            return targetBlockEditor.replaceSelectedContent(
              selection,
              event.data,
            );
          },
          blockSelection: () => {
            const contentSubset =
              event.data.type === 'block'
                ? event.data.content
                : [this.createDefaultBlock(event.data.content)];

            const lastBlock = contentSubset[contentSubset.length - 1];

            return {
              contentSubset: contentSubset,
              selection: textSelection(
                contentSubset.length - 1,
                lastBlock ? getEndOfBlock(lastBlock) : contentSelection(0),
              ),
            };
          },
        });

        if (!result) return false;

        this.#store.set(result);
        this.#notifyContentSubscribers();

        return true;
      }
      case 'turnInto': {
        if (currentState.selection == null) return false;

        const result = replaceSelectionWith(currentState, {
          textSelection: (selectedBlock, selection) => {
            const newBlock = this.#turnInto(
              selectedBlock,
              event.data.blockType,
            );
            if (!newBlock) return;

            return {
              contentSubset: [newBlock],
              selection: textSelection(0, selection),
            };
          },
          blockSelection: (selectedBlocks) => {
            const newBlocks = selectedBlocks.map((selectedBlock) =>
              this.#turnInto(selectedBlock, event.data.blockType),
            );

            if (
              arrayIs(newBlocks, (block): block is AvailableBlocks => !!block)
            ) {
              return {
                contentSubset: newBlocks,
                selection: blockSelection(0, newBlocks.length - 1),
              };
            }
            return;
          },
        });

        if (!result) return false;

        this.#store.set(result);
        this.#notifyContentSubscribers();

        return true;
      }
      case 'toggleHighlight': {
        const result = replaceSelectionWith(currentState, {
          textSelection: (selectedBlock, selection) => {
            const targetBlockEditor = this.#generateBlockEditor(selectedBlock);

            return targetBlockEditor.toggleHighlight(selection);
          },
          blockSelection: () => {
            return;
          },
        });

        if (!result) return false;

        this.#store.set(result);
        this.#notifyContentSubscribers();

        return true;
      }
      case 'pressShiftArrowUp': {
        return this.#applyActionResult(
          pressShiftArrowUp(this.#generateBlockEditor, currentState),
        );
      }
      case 'pressShiftArrowDown': {
        return this.#applyActionResult(
          pressShiftArrowDown(this.#generateBlockEditor, currentState),
        );
      }
      case 'pressArrowUp': {
        return this.#applyActionResult(
          pressArrowUp(
            this.#generateBlockEditor,
            currentState,
            event.data.isOnFirstLineOfBlock,
            event.data.fancyNavUp,
          ),
        );
      }
      case 'pressArrowDown': {
        return this.#applyActionResult(
          pressArrowDown(
            this.#generateBlockEditor,
            currentState,
            event.data.isOnLastLineOfBlock,
            event.data.fancyNavDown,
          ),
        );
      }
      case 'pressEnter': {
        return this.#applyActionResult(
          pressEnter(
            this.#generateBlockEditor,
            this.createDefaultBlock,
          )(currentState),
        );
      }
      case 'delete': {
        return this.#applyActionResult(
          pressDelete<AvailableBlocks>(
            (block) => this.#generateBlockEditor(block).Delete,
            this.createDefaultBlock,
          )(currentState),
        );
      }
      case 'pressBackspace': {
        const actionResult = pressBackspace<AvailableBlocks>(
          (block) => this.#generateBlockEditor(block).Backspace,
          this.createDefaultBlock,
        )(currentState);

        const adaptedActionResult =
          actionResult && actionResult.type === 'merge'
            ? undefined
            : actionResult;

        return this.#applyActionResult(adaptedActionResult);
      }
      case 'pressShiftEnter': {
        return this.#applyActionResult(
          pressShiftEnter(this.#generateBlockEditor)(currentState),
        );
      }
      case 'addNewBlock': {
        const [newState] = AddBlockEditorActions.addNewBlock(
          this.#generateBlockEditor,
        )(currentState, event.data.targetIndex, event.data.newBlock);
        return this.#applyActionResult(newState);
      }
      case 'replaceNewBlock': {
        const [newState] = AddBlockEditorActions.replaceNewBlock(
          this.#generateBlockEditor,
        )(currentState, event.data.newContent, event.data.targetBlockId);
        return this.#applyActionResult(newState);
      }
    }

    return matchSelectionType({
      blockSelectionAction: (selection) => {
        switch (event.type) {
          case 'replaceSelectedContentWithPlaintext': {
            const plaintext = event.data.content;

            const result = blockSelectionReplaceSelectedContentWithPlaintext(
              {
                content: currentState.content,
                selection,
              },
              this.createDefaultBlock,
              plaintext,
            );

            if (!result) return false;

            this.#store.set(result);

            this.#notifyContentSubscribers();
            return true;
          }
        }

        // unhandled events
        return false;
      },
      textSelectionAction: (selection) => {
        const currentState = this.#store.get();
        const targetBlock = currentState.content[selection.index];
        if (targetBlock) {
          const targetBlockEditor = this.#generateBlockEditor(targetBlock);
          targetBlockEditor.setEditorDispatch(this.dispatch);

          switch (event.type) {
            case 'pressArrowLeft': {
              return targetBlockEditor.dispatch(
                {
                  type: 'pressArrowLeft',
                  previousBlock: currentState.content[selection.index - 1],
                },
                selection,
              );
            }
            case 'pressArrowRight': {
              return targetBlockEditor.dispatch(
                {
                  type: 'pressArrowRight',
                  nextBlock: currentState.content[selection.index + 1],
                },
                selection,
              );
            }
            case 'replaceSelectedContentWithPlaintext': {
              return targetBlockEditor.replaceSelectedContentWithPlaintext(
                selection,
                event.data.content,
              );
            }
            default: {
              return targetBlockEditor.dispatch(event, selection);
            }
          }
        } else {
          console.log(
            'event fired on nonexistent target.  index: , editors: ',
            selection.index,
            currentState.content,
          );
        }

        // unhandled events
        return false;
      },
    })(currentState.selection);
  };
}
