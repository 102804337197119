import { EngagementSummary } from 'app/api/endpoints/createAnalyticsApi.ts';
import minutesToTimeString from '../../../../../junkDrawer/minutesToTimeString.js';
import {
  AnalyticsBreakdown,
  AnalyticsBreakdownSection,
  AnalyticsBreakdownTitle,
  AnalyticsBreakdownValue,
} from './AnalyticsBreakdown.tsx';
import ViewerEngagementChart from './ViewEngagementChart.js';
import styles from './ViewerEngagementSummary.module.scss';

type ViewerEngagementSummaryProps = {
  summary: EngagementSummary;
};

const ViewerEngagementSummary: React.FC<ViewerEngagementSummaryProps> = ({
  summary,
}) => {
  const percentViewed = Math.round(summary.viewedPercent * 100);

  return (
    <AnalyticsBreakdown>
      <AnalyticsBreakdownSection className={styles.summaryChartPanel}>
        <ViewerEngagementChart percentViewed={percentViewed} />
      </AnalyticsBreakdownSection>

      <AnalyticsBreakdownSection>
        <AnalyticsBreakdownTitle>
          Total Time Spent By Viewers
        </AnalyticsBreakdownTitle>

        <AnalyticsBreakdownValue data-testid="total-time-spent">
          {minutesToTimeString(summary.totalMinutesSpent)}
        </AnalyticsBreakdownValue>
      </AnalyticsBreakdownSection>

      <AnalyticsBreakdownSection>
        <AnalyticsBreakdownTitle>
          Avg Time Spent By Viewer
        </AnalyticsBreakdownTitle>

        <AnalyticsBreakdownValue data-testid="average-time-spent">
          {minutesToTimeString(summary.averageTimeSpent)}
        </AnalyticsBreakdownValue>
      </AnalyticsBreakdownSection>
    </AnalyticsBreakdown>
  );
};

export default ViewerEngagementSummary;
