import React, { useRef, useState } from 'react';
import { CommentsState } from '../useComments/useComments.ts';
import styles from './CommentsActionBar.module.scss';
import IconButton from '../../../../../../design-system/atoms/IconButton.tsx';
import WithTooltip from '../../../../../../design-system/organisms/WithTooltip.tsx';
import KebabMenu from '../../../../../../design-system/organisms/KebabMenu.tsx';
import Pill from '../../../../../../design-system/atoms/Pill.tsx';
import mergeRefs from '../../../../../../junkDrawer/mergeRefs.js';

const CommentsActionBar: React.FC<{ commentsState: CommentsState | null }> = ({
  commentsState,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const kebabRef = useRef<HTMLButtonElement>(null);

  if (!commentsState) return null;
  return (
    <span className={styles.actionBar}>
      {commentsState.isFilteredResolved ? (
        <WithTooltip<HTMLElement>
          text="Clear filter"
          enabled={!menuOpen}
          usePortal
        >
          {(tooltipTargetRef, tooltipListeners) => (
            <Pill
              className={styles.actionBar__resolveClosePill}
              label={
                <>
                  Resolved
                  <IconButton
                    {...tooltipListeners}
                    ref={mergeRefs([tooltipTargetRef])}
                    name="close"
                    aria-label="Clear filter"
                    className={styles.actionBar__resolveCloseIcon}
                    onClick={() => {
                      commentsState.showResolved(false);
                    }}
                  />
                </>
              }
              size="medium"
              color="green"
            />
          )}
        </WithTooltip>
      ) : (
        <WithTooltip<HTMLElement>
          text="Filter by comment type"
          enabled={!menuOpen}
          usePortal
        >
          {(ref, listeners) => (
            <IconButton
              {...listeners}
              name="filter"
              aria-label="Filter by comment type"
              className={styles.actionBar__filterIcon}
              ref={mergeRefs([ref, kebabRef])}
              onClick={(e) => {
                e.preventDefault();
                setMenuOpen(true);
              }}
            />
          )}
        </WithTooltip>
      )}
      <KebabMenu
        className={styles.actionBar__filterMenu}
        isOpen={menuOpen}
        usePortal
        onClose={() => setMenuOpen(false)}
        elementRef={kebabRef}
        items={[
          {
            label: 'Resolved',
            iconName: 'checkmark',
            color: 'normal',
            onClick: () => {
              commentsState.showResolved(!commentsState.isFilteredResolved);
              setMenuOpen(false);
            },
          },
        ]}
      />
    </span>
  );
};

export default CommentsActionBar;
