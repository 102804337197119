import { EditorStateGeneric } from '../EditorStateGeneric.js';
import {
  BlockSelection,
  getEndOfSelection,
} from '../selection/BlockSelection.js';
import { isTextSelection } from '../EditorSelection.js';
import { TextSelection, textSelection } from '../selection/TextSelection.js';
import ContentSelection, {
  contentSelection,
} from '../selection/contentSelection/ContentSelection.js';
import getSelectedBlock from '../../pages/zeck/editor/BodyEditor/getSelectedBlock.js';

function blockSelectionAction<BlockType>(
  generateBlockEditor: (b: BlockType) => {
    getEndOfBlockSelection: () => ContentSelection;
  },
  {
    content,
    selection,
  }: {
    selection: BlockSelection;
    content: BlockType[];
  },
) {
  const endOfSelection = getEndOfSelection(selection);
  const nextBlockIndex = endOfSelection + 1;
  const nextBlock = content[nextBlockIndex];

  if (nextBlock) {
    return {
      content,
      selection: textSelection(nextBlockIndex, contentSelection(0)),
    };
  }

  const lastBlock = content[endOfSelection];

  if (!lastBlock) {
    return {
      content,
      selection: textSelection(endOfSelection, contentSelection(0)),
    };
  }

  const lastBlockEditor = generateBlockEditor(lastBlock);

  return {
    content,
    selection: textSelection(
      endOfSelection,
      lastBlockEditor.getEndOfBlockSelection(),
    ),
  };
}

function textSelectionAction<BlockType>(
  generateBlockEditor: (b: BlockType) => {
    pressArrowDown: (isOnLastLineOfBlock: () => boolean) => boolean;
  },
  {
    content,
    selection,
  }: {
    selection: TextSelection;
    content: BlockType[];
  },
  isOnLastLineOfBlock: (blockIndex: number) => boolean,
  fancyNavDown: (
    currentBlockIndex: number,
    newBlockIndex: number,
  ) => ContentSelection | false,
) {
  const selectedBlock = getSelectedBlock(content, selection);
  if (!selectedBlock) return;
  const targetBlockEditor = generateBlockEditor(selectedBlock);

  const isExitingBlock = targetBlockEditor.pressArrowDown(() =>
    isOnLastLineOfBlock(selection.index),
  );

  if (!isExitingBlock) return;
  const nextIndex = selection.index + 1;
  const nextBlock = content[nextIndex];

  if (!nextBlock) return;

  let newSelection: ContentSelection | false;

  try {
    newSelection = fancyNavDown(selection.index, nextIndex);
    // fancyNavUp will throw if point is offscreen.
    // For example: you are trying to arrow up to a block that has been scrolled offscreen
  } catch {
    newSelection = contentSelection(0);
  }

  if (!newSelection) return;

  return {
    content,
    selection: textSelection(nextIndex, newSelection),
  };
}

export default function pressArrowDown<BlockType>(
  generateBlockEditor: (b: BlockType) => {
    getEndOfBlockSelection: () => ContentSelection;
    pressArrowDown: (isOnLastLineOfBlock: () => boolean) => boolean;
  },
  { content, selection }: EditorStateGeneric<BlockType>,
  isOnLastLineOfBlock: (blockIndex: number) => boolean,
  fancyNavDown: (
    currentBlockIndex: number,
    newBlockIndex: number,
  ) => ContentSelection | false,
): {
  content: BlockType[];
  selection: TextSelection;
} | void {
  // only handles block selection currently

  if (!selection) return;
  if (isTextSelection(selection))
    return textSelectionAction(
      generateBlockEditor,
      {
        selection,
        content,
      },
      isOnLastLineOfBlock,
      fancyNavDown,
    );
  return blockSelectionAction(generateBlockEditor, { selection, content });
}
