import { useEffect, useState } from 'react';
import { getHighlightFromElement } from 'editor-content/html/HighlightElement.ts';
import isHTMLElement from '../../../../../junkDrawer/isHTMLElement.js';

const useDetectHighlightHover = (): string[] => {
  const [hoveredHighlightIds, setHoveredHighlightIds] = useState<string[]>([]);

  useEffect(() => {
    const cleanups = Array.from(
      document.querySelectorAll('[data-nodetype="highlight"]'),
    ).map((el) => {
      const enterListener = () => {
        if (!isHTMLElement(el)) return;
        const highlight = getHighlightFromElement(el, []);
        if (!highlight) return;
        setHoveredHighlightIds(highlight.ids);
      };
      el.addEventListener('mouseenter', enterListener);

      const leaveListener = () => {
        setHoveredHighlightIds([]);
      };

      el.addEventListener('mouseleave', leaveListener);

      return () => {
        el.removeEventListener('mouseenter', enterListener);
        el.removeEventListener('mouseleave', leaveListener);
      };
    });

    return () => {
      cleanups.forEach((fn) => fn());
    };
  });

  return hoveredHighlightIds;
};

export default useDetectHighlightHover;
