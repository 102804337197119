import ContentSelection, {
  isCollapsed,
} from '../../../../editor/selection/contentSelection/ContentSelection.ts';
import HoverNextToPoint from '../../../../domHelpers/hoverNextTo/HoverNextToPoint.tsx';
import { RefCallback } from 'react';
import { useGetDomRangeFromSelection } from '../../../../editor/selection/contentSelection/selectionHelpers.ts';
import mergeRefs from '../../../../junkDrawer/mergeRefs.ts';

type HoverNextToSelectionProps = {
  children: React.ReactNode;
  selection: ContentSelection | null;
  getDomRange(): Range | null;
};

export const WithHoverNextToSelection: React.FC<{
  children: (ref: RefCallback<HTMLElement>) => React.ReactNode;
  hoverContent: React.ReactNode;
  selection: ContentSelection | null;
}> = ({ children, selection, hoverContent }) => {
  const { ref, getDomRange } = useGetDomRangeFromSelection(selection);

  return (
    <>
      <HoverNextToSelection selection={selection} getDomRange={getDomRange}>
        {hoverContent}
      </HoverNextToSelection>
      {children(mergeRefs([ref]))}
    </>
  );
};

const HoverNextToSelection = ({
  selection,
  getDomRange,
  children,
}: HoverNextToSelectionProps) => {
  if (!selection || isCollapsed(selection)) return null;

  return (
    <HoverNextToPoint
      usePortal
      viewportPolicy="none"
      containerStyles={{ zIndex: 'initial' }}
      getPoint={(container) => {
        const selection = getDomRange();
        if (!selection) {
          return null;
        }

        const rect = selection.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();

        return [
          (rect.left + rect.right) / 2 - containerRect.width / 2,
          rect.bottom + 16,
        ];
      }}
    >
      {children}
    </HoverNextToPoint>
  );
};

export default HoverNextToSelection;
