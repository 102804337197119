import { useRef, useState } from 'react';
import styles from './CommentsMenu.module.scss';
import UserInitials from '../../../../../design-system/atoms/UserInitials.tsx';
import IconButton from '../../../../../design-system/atoms/IconButton.tsx';
import HoverMenu, {
  HoverMenuButtonItem,
} from '../../../../../design-system/organisms/HoverMenu.tsx';
import EditorCommentForm from './EditorCommentForm.tsx';
import { CommentContentNode } from 'editor-content/CommentContent.js';
import CommentEditorAdapter from '../../../../../commentEditor/CommentEditorAdapter.ts';
import { AvailableTag } from '../../../../../types/AvailableTag.ts';

type CommentProps = {
  idx: number;
  userInitials: string;
  userName: string;
  onSaveEdit: (editedText: CommentContentNode[]) => Promise<void>;
  onShowDelete: () => void;
  allowEdit: boolean;
  edited: boolean;
  availableTags: AvailableTag[];
  commentContent: CommentContentNode[];
  scrollContainerRef: React.RefObject<HTMLElement>;
};

const Comment: React.VFC<CommentProps> = ({
  idx,
  userInitials,
  userName,
  onSaveEdit,
  onShowDelete,
  allowEdit,
  edited,
  availableTags,
  commentContent,
  scrollContainerRef,
}) => {
  const [uiState, setUiState] = useState<'viewing-comment' | 'editing'>(
    'viewing-comment',
  );

  switch (uiState) {
    case 'viewing-comment':
      return (
        <ViewComment
          {...{
            idx,
            userInitials,
            userName,
            allowEdit,
            commentContent,
            onShowDelete,
            edited,
            onClickEdit: () => setUiState('editing'),
          }}
        />
      );
    case 'editing':
      return (
        <EditComment
          {...{
            idx,
            userInitials,
            userName,
            commentContent,
            availableTags,
            scrollContainerRef,
            async onSubmit(newContent) {
              await onSaveEdit(newContent);
              setUiState('viewing-comment');
            },
          }}
        />
      );
  }
};

type EditCommentProps = {
  idx: number;
  userInitials: string;
  userName: string;
  commentContent: CommentContentNode[];
  onSubmit: (newCommentContent: CommentContentNode[]) => Promise<void>;
  availableTags: { userId: string; displayName: string }[];
  scrollContainerRef: React.RefObject<HTMLElement>;
};

const EditComment: React.VFC<EditCommentProps> = ({
  userInitials,
  userName,
  commentContent,
  onSubmit,
  availableTags,
  scrollContainerRef,
}) => {
  return (
    <div data-testid={'selection-comment'}>
      <div className={styles.comment__header}>
        <UserInitials size={'small'} className={styles.comment__initials}>
          {userInitials}
        </UserInitials>
        <address className={styles.comment__username}>{userName}</address>
      </div>

      <EditorCommentForm
        className={styles.comment__editForm}
        onSubmit={onSubmit}
        initialContent={commentContent}
        placeholder={'Edit comment...'}
        submitText={'Save'}
        availableTags={availableTags}
        autofocus
        scrollContainerRef={scrollContainerRef}
      />
    </div>
  );
};

type ViewCommentProps = {
  idx: number;
  userInitials: string;
  userName: string;
  onShowDelete: () => void;
  allowEdit: boolean;
  edited: boolean;
  commentContent: CommentContentNode[];
  onClickEdit: () => void;
};

const ViewComment: React.VFC<ViewCommentProps> = ({
  userInitials,
  userName,
  allowEdit,
  commentContent,
  onShowDelete,
  edited,
  onClickEdit,
}) => {
  const kebabMenuRef = useRef<HTMLDivElement>(null);
  const [isHovered, setIsHovered] = useState(false);
  const [showKebabMenu, setShowKebabMenu] = useState(false);

  return (
    <div
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
      data-testid={'selection-comment'}
    >
      <div className={styles.comment__header} ref={kebabMenuRef}>
        <UserInitials size={'small'} className={styles.comment__initials}>
          {userInitials}
        </UserInitials>
        <address className={styles.comment__username}>{userName}</address>
        {isHovered && (
          <IconButton
            name="kebab"
            aria-label="manage comment"
            onClick={() => {
              setShowKebabMenu(true);
            }}
          />
        )}
        <HoverMenu
          elementRef={kebabMenuRef}
          positionStrategy={(el, popoverEl) => {
            const rect = el.getBoundingClientRect();
            const popoverRect = popoverEl.getBoundingClientRect();
            return [rect.right - popoverRect.width, rect.bottom];
          }}
          onClose={() => {
            setShowKebabMenu(false);
          }}
          isOpen={showKebabMenu}
          usePortal
        >
          <HoverMenuButtonItem
            iconName="trash"
            color="danger"
            onClick={(e) => {
              e.stopPropagation();
              setShowKebabMenu(false);
              onShowDelete();
            }}
          >
            Delete
          </HoverMenuButtonItem>
          {allowEdit && (
            <HoverMenuButtonItem
              iconName="edit"
              color="normal"
              onClick={() => {
                onClickEdit();
              }}
            >
              Edit
            </HoverMenuButtonItem>
          )}
        </HoverMenu>
      </div>

      <div className={styles.comment__content}>
        <span
          dangerouslySetInnerHTML={{
            __html: CommentEditorAdapter.toHTMLString(commentContent),
          }}
        />
        {edited && (
          <span className={styles.comment__editedMarker}> (edited)</span>
        )}
      </div>
    </div>
  );
};

export default Comment;
